import React, { useState } from "react";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";

const AuthPage = ({ auth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(emailRegex.test(newEmail) ? "" : "Invalid email address");
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const user = response.user;

      console.log("Login successful", user);
      navigate("/dashboard");
    } catch (error) {
      console.error("Login failed:", error);
      const errorMessage = error.message;
      console.error(errorMessage || "Login failed");
      setLoginError(errorMessage || "Login failed");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleLogin();
  };

  return (
    <div className="bg-gradient-to-tl from-neutral-800 via-black to-stone-900 h-screen w-screen font-inter">
      <div className="flex flex-col items-center flex-1 h-full justify-center px-4 sm:px-0">
        <div
          className="flex rounded-lg shadow-lg w-full sm:w-3/4 lg:w-2/3 bg-white sm:mx-0"
          style={{ height: "550px" }}
        >
          <div
            className="hidden md:block md:w-1/2 rounded-l-lg"
            style={{
              background:
                "url('https://sun9-30.userapi.com/impg/1Yd-DU-n4mnyuph2nNT96PFfgmY1M4B5n-u-iA/4NzFXuKqMR8.jpg?size=939x941&quality=96&sign=43cd9ec6b46927ccd46dff71b32da3f8&type=album')",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="flex flex-col w-full md:w-1/2 p-4">
            <div className="flex flex-col flex-1 justify-center mb-8">
            <h1 className="text-2xl text-center font-semibold text-black">
                Welcome Back
              </h1>
              <div className="w-full mt-4">
                <form
                  className="form-horizontal w-3/4 mx-auto"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col mt-4">
                    <input
                      id="email"
                      type="text"
                      className={`flex-grow h-8 py-6 px-2 border rounded bg-transparent ${
                        emailError ? "border-red-500" : ""
                      }`}
                      name="email"
                      value={email}
                      required
                      onChange={handleEmailChange}
                      placeholder="Email"
                    />
                    {emailError && (
                      <p className="text-red-500 text-xs mt-1">{emailError}</p>
                    )}
                  </div>
                  <div className="flex flex-col mt-4 relative">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      className="flex-grow h-8 py-6 px-2 border rounded bg-transparent pr-10"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={handleTogglePasswordVisibility}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2"
                    >
                      {showPassword ? "👁️" : "👁️‍🗨️"}
                    </button>
                  </div>

                  <div className="flex flex-col mt-8">
                    <button
                      type="submit"
                      className="bg-[black] hover:bg-blue-700 text-white text-sm font-semibold py-4 px-6 rounded transition-transform duration-300 ease-in-out"
                    >
                      Login
                    </button>
                  </div>
                </form>

                {loginError && (
                  <div className="text-red-500 text-center mt-4">
                    {loginError}
                  </div>
                )}

                <div className="text-center mt-4">
                  <p className="text-black">
                    Don't have an account?{" "}
                    <LinkRouter
                      to="/register"
                      className="text-blue-500 hover:underline"
                    >
                      Register here
                    </LinkRouter>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
