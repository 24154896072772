import React, { useState } from "react";
import arrow from "../svg/droparrow.svg";

function Questions() {
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleAccordion = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const questionsData = [
    {
      question: "What is a VR education product?",
      content: `
      MetaStudy is an innovative technology that immerses students in a virtual space where they can study various subjects, including physics and the history of Kazakhstan, in an interactive format.`,
    },
    {
      question:
        "What are the advantages of VR education compared to traditional methods?",
      content: `VR education enriches the learning experience, allowing students to interact with educational materials, explore, and immerse themselves in historical events or physical phenomena. It promotes better understanding and retention of information.`,
    },
    {
      question:
        "Which specific educational programs and subjects does your VR product cover?",
      content: `
      We offer a variety of educational programs, including the history of Kazakhstan and physics. Our catalog is regularly updated to meet the needs of educational institutions.`,
    },
    {
      question: "What are the technical requirements for using the VR product?",
      content: `To use our VR product, you will need a compatible VR headset and access to a device with suitable specifications. We provide VR goggles and detailed technical recommendations.`,
    },
    {
      question:
        "Can I expect assistance with the installation and setup of the VR education product?",
      content: `Yes, of course! We provide full support for installation and setup. Our specialists will come to you, install all the necessary equipment, and configure the software, ensuring readiness for use.`,
    },
    {
      question: "How is the delivery and installation of the equipment done?",
      content: `We provide equipment delivery directly to your educational institution. Our specialists professionally install and configure the headsets, ensuring the correct functioning of the product.`,
    },
    {
      question: "Is training provided on how to use the VR product?",
      content: `Yes, we provide training. Our experts will train teachers on using the headsets and software so that you can integrate it effectively into the educational process.`,
    },
];


  return (
    <div className="max-w-screen-2xl mx-auto  p-2 px-4 items-center font-['Inter'] mt-[150px]">
      <h1
        id="FAQ"
        className="text-xl font-semibold text-left text-[#8E8E8E] mb-6  "
      >
        — Frequently Asked Questions
      </h1>
      {questionsData.map((item, index) => (
         <div
         className={`border-y ${
           openIndexes.includes(index) ? "border-[#4F9EFF]" : "border-[#4F9EFF] p-2 "
         } hover:border-[#4F9EFF]`}
       >
          <h2>
            <button
              type="button"
              className={`flex items-center justify-between w-full py-5 font-medium text-left text-black-500 ${
                openIndexes.includes(index) ? "bg-transparent text-white" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <div className="flex flex-col">
                <span className="font-bold  text-[#8E8E8E] text-xl font-['Inter']">
                  {item.question}
                </span>
              </div>
              <img
                src={arrow}
                className={`w-3 h-3 shrink-0 transform ${
                  openIndexes.includes(index) ? "rotate-180" : "rotate-0"
                }`}
                alt="Arrow"
              />
            </button>
          </h2>
          {openIndexes.includes(index) && (
            <div className="text-white text-xl   font-semibold font-['Inter']">
              {item.content}
            </div>
          )}
          <hr className="mt-2 h-[1px] bg-black border-0" />
        </div>
      ))}
    </div>
  );
}

export default Questions;
