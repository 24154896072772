import { useState, React } from "react";
import gif1 from "../GIF/gif1.gif";
import gif2 from "../GIF/gif2.gif";
import gif3 from "../GIF/gif3.gif";
import gif4 from "../GIF/gif4.gif";
import gif5 from "../GIF/gif5.gif";
import gif6 from "../GIF/gif6.gif";
import gifhs1 from "../GIF/gifhs1.gif";
import gifhs2 from "../GIF/gifhs2.gif";
import gifhs3 from "../GIF/gifhs3.gif";
import gifhs4 from "../GIF/gifhs4.gif";
import gifhs5 from "../GIF/gifhs5.gif";
import gifhs6 from "../GIF/gifhs6.gif";
import gifhs7 from "../GIF/gifhs7.gif";
import gifhs8 from "../GIF/gifhs8.gif";
import more from "../images/more.png";
import "../CSS/main.css";
function Lessons() {
  const physicsLessons = [
    // механические явления
    {
      title: "Механические явления",
      description:
        "Механические явления - физические процессы, связанные с движением и взаимодействием объектов и силами.",
      imgSrc: gif1,
      modaltitle: "Механические явления",
      modalcontent: ` Масса
           Архимедова сила
           Динамическая деформация
         Закон Ньютона 1
          Закон Ньютона 2
           Закон Ньютона 3
           Шар Паскаль
           Ньютоновая трубка
           Определение ускорения тела при равномерного движении.
          Нормальное, умеренно прогрессирующее и деградационное движение.
           Принцип работы насоса
           Контактная посуда
           Давление
          Принцип работы Барометра
          Закон сохранения энергии
          Определение условий равновесия плеч
          Сила трения
         Траектория перемещения метённого тела с наклонным и углом
          Закон инерции
          Простые движения
           Математический маятник,
         Определение массы и плотности твердого тела
         Определение вытесняющей силы, действующей на тело массой 200 г внутри воды
           Определение параметров организма
           Гидравлический пресс.`,
    },
    //  Световые явления
    {
      title: "Тепловые явления",
      modaltitle: "Тепловые явления",
      description:
        "Тепловые явления - изучение тепла и его воздействия на материалы.",
      modalcontent: `Изохора
          Изобара
          Изотермия
          Теплопроводность
          Единицы измерения температуры
          Паровая машина
          Двигатель
          Сравнение количества тепла при смешивании воды с различными температурами.
          Размножение льда`,
      imgSrc: gif5,
    },

    // Электрические явления
    {
      title: "Электрические явления",
      modaltitle: "Электрические явления",
      description:
        "Электрические явления - связаны с движением и взаимодействием электрических зарядов. ",
      modalcontent: `Получение электроэнергии от ветровой мощности
          Получение электроэнергии от солнечной мощности
          Генератор Ван де Граф
          Принцип работы генератора
          Действие каждого одноименного зарядного тела
          Действие заряженных тел
          Изучение закона Ома для части цепи
          Регулирование силы тока реостатом
          Измерение напряжения на различных участках электрической цепи
          Измерение сопротивления проводника с помощью амперметра и вольтметра
          Определение сопротивления проводимости
          Устройство и принцип работы электроскопа
          Закон сохранения электрического заряда
          Измерение КПД установки с электрообогревателем
          Электрификация тела
          Определение сопротивления металлов в зависимости от объема`,
      imgSrc: gif3,
    },
    // Электро-магнитные явления
    {
      title: "Электро-магнитные явления",
      modaltitle: "Электро-магнитные явления",
      description:
        "Электромагнитные явления - изучение взаимодействия полей и их роль в технологиях.",
      imgSrc: gif4,
      modalcontent: `Принцип работы трансформатора
          Положение Ленца
          Исследование явления электромагнитной индукции
          Устройство генератора переменного тока
          Конструкция генератора постоянного тока
          Образование электрического тока в электролизе
          Трехфазный электрический ток
          Радио Попова`,
    },
    // Тепловые явления
    {
      title: "Световые явления",
      modaltitle: "Световые явления",
      description:
        "Световые явления - изучение света, оптики и его воздействия.",

      imgSrc: gif2,
      modaltitle: "Световые явления",
      modalcontent: `Исследование зависимости угла поступления света
          Исследование зависимости угла поломки к углу падения света
          Измерение основных фокусных расстояний и оптических линз сбора усилий
          Контроль дисперсии света с помощью призмы
          Распространение прямого света
          Лучи в собирающем линзе
          Лучи в рассеянных линзах
          Принцип работы проекционного аппарата и фотоаппарата
          Изучение видения изображения в очках с моделью глаза, распылительными линзами и сборными линзами.`,
    },
    {
      title: "Физические явления",
      imgSrc: gif6,
      modaltitle: "Физические явления",
      description:
        "Физические явления- это явления, при которых не происходит превращение одних веществ в другие.",
      modalcontent: `1. Знакомство с физическими устройствами.
      2.Сравнение массы тела в равном объеме
          Твердые тела
          Жидкие тела
          3. Астрофизика
          Характеристика планеты Земли
          Описание планеты Юпитер
          Описание планеты Сатурн
          Описание планеты Марс
          Венера
          Уран
          Нептун
          4. Определение размеров мелких тел `,
    },
  ];

  const historyLessons = [
    {
      title: "Жизнь древних людей Казахстана",
      // description: "История эпох:",
      imgSrc: gifhs1,
      modalcontent: `Каменный век (2,5млн – 2800 до н.э.)
          Бронзовый век ( 2-1 тыс до н.э.)`,
    },
    {
      title: "Эпоха железа",
      // description: "Саки, Усуни, Кангюи, Гунны",
      imgSrc: gifhs2,
      modalcontent: ` Саки (VIII–III вв. до н. э.)
          Усуни,(IV в до н.э-III в н.э) Кангюи, (III до н.э-IV в н.э)Гунны.( III-II вв до н.э–V в н.э)`,
    },
    {
      title: "История Казахстана в тюркский период",
      // description:
      //   "Тюркский каганат, Западнотюркский и Восточнотюркский каганаты, Тюргешский каганат",
      imgSrc: gifhs3,
      modalcontent: ` Тюркский каганат (552-603гг)
         Западнотюркский (603-704гг) и Восточнотюркский (682-744гг) каганаты
          Тюргешский каганат (704-940гг) 
          Атлахская битва (751гг)
         Карлукский каганат (756-940гг)
           Государство Огузов (нач. ІХ – середина ХІ вв)
           Кимакский каганат (нач. ІХ – середина ХІ вв)`,
    },
    {
      title: "Казахстан в середине Х - нач. ХIIIвв",
      // description:
      //   "Государство Караханидов, Государство Каракитаев, Найманы, Кереиты, Жалаиры, Кипчакское ханство",
      imgSrc: gifhs5,
      modalcontent: `Государство Караханидов.(942-1212гг) 
          Государство Каракитаев. (1128-1212 гг
      Найманы, Кереиты, Жалаиры(нач. ХІІ -ХІІІ вв)
        Кипчакское ханство. (нач. ХІ – ХІІІ вв)`,
      modalcontent: `Государство Караханидов.(942-1212гг) 
         Государство Каракитаев. (1128-1212 гг
       Найманы, Кереиты, Жалаиры(нач. ХІІ -ХІІІ вв)
        Кипчакское ханство. (нач. ХІ – ХІІІ вв)`,
    },
    {
      title: "Культура Казахстана в Х – начале ХIII вв",
      // description: "Развитие городов и городское благоустройство",
      imgSrc: gifhs6,
      modalcontent: `Развитие городов и городское благоустройство. (нач. ХІ в – ХІІІ вв)`,
    },
    {
      title: "Казахстан в ХІІІ-ХV вв",
      // description:
      //   "Монгольское завоевание на территории Казахстана, Ак Орда, Могулистан, Государстово Тимура и тимуридов, Ханство Абулхаира, Ногайская Орда",
      imgSrc: gifhs4,
      modalcontent: `Монгольское завоевание на территории Казахстана.
          Ак Орда. (ХІІІв – нач ХVвв)
           Могулистан. (середина ХІV в – нач. ХVІ в)
           Государстово Тимура и тимуридов.
           Ханство Абулхаира.
          Ногайская Орда`,
    },
    {
      title: "Казахское ханство в ХV-ХVII вв",
      // description: "Образование Казахского ханство. (ХV в - нач. ХVІ в.)",
      imgSrc: gifhs7,
      modalcontent: `Касым хан (1511 – 1518 гг)
           Хакназар хан (1538-1580 гг)
           Тауекел хан (1583 – 1598 гг)
           Есим хан (1598 -1628 гг)
           Жангир хан (1529 -1652 гг)
         Тауке хан (1680 – 1715 жж )`,
    },
    {
      title: "Казахская культура XV-XVII веков.",
      // description: "Жилище казахского народа, Ремесло",
      imgSrc: gifhs8,
      modalcontent: `Жилище казахского народа
         Ремесло`,
    },
  ];
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const openModal = (index, type) => {
    setSelectedCardIndex({ index, type });
  };

  const closeModal = () => {
    setSelectedCardIndex(null);
  };

  const renderLessonCards = (lessons, type) => {
    return lessons.map((lesson, index) => (
      <div
        key={index}
        className="border border-neutral-400 rounded-lg shadow hover:border-[#005AC5] "
      >
        <div className="image-container">
          <img
            loading="lazy"
            className="rounded-t-lg"
            src={lesson.imgSrc}
            alt=""
          />
          <div className="replacement-div p-4">
            <p className="text-white text-2xl font-normal font-jost  mb-1">
              {lesson.title}
            </p>
            <p className="text-white text-base font-inter  font-medium  description-mobile hidden lg:block lg:h-[125px] h-[110px]">
              {lesson.description}
            </p>
            <button
              onClick={() => openModal(index, type)}
              className="px-[18.50px] py-[6px] bg-neutral-100 rounded-[200px] border border-white justify-center items-center gap-2.5 absolute bottom-4 left-4"
              style={{ zIndex: 1 }}
            >
              <span className="text-[14px]  font-medium  text-black font-inter">
                Подробнее
              </span>
            </button>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div
      id="lessons"
      className="px-4 mt-24 p-2 mx-auto items-center max-w-screen-2xl "
    >
      {/* Первый аккордеон */}
      <div id="physics">
        <div className="mb-4">
          <h1 className="text-neutral-400 text-lg font-normal whitespace-nowrap mt-4 font-inter">
            — Практические занятие по физике
          </h1>
        </div>
        <div className="h-[0px] border border-neutral-400"></div>
        <div className=" mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {renderLessonCards(physicsLessons, "physics")}
        </div>
      </div>

      {/* Второй аккордеон */}
      {/* <div id="history">
        <div className="mb-4">
          <h1 className="text-neutral-400 text-lg font-normal whitespace-nowrap mt-4 font-inter">
            — Практические занятие по истории
          </h1>
        </div>
        <div className="h-[0px] border border-neutral-400 mb-4 mt-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {renderLessonCards(historyLessons, "history")}
        </div>
      </div> */}

      {selectedCardIndex !== null && (
        <Modal
          lesson={
            selectedCardIndex.type === "physics"
              ? physicsLessons[selectedCardIndex.index]
              : historyLessons[selectedCardIndex.index]
          }
          onClose={closeModal}
        />
      )}
    </div>
  );
}
const Modal = ({ lesson, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center z-50 animate-fadeIn">
      <div
        className="w-full h-full bg-gray-900 opacity-75"
        onClick={onClose}
      ></div>
      <div className="fixed top-0 right-0 h-full z-50">
        <div className="bg-black border border-[#005AC5] rounded-md shadow-md max-w-lg p-4 md:p-8 lg:p-10 h-full">
          <div
            className="p-4 modal-scroll"
            style={{ maxHeight: "100vh", overflowY: "auto" }}
          >
            <div className="flex justify-end">
              <button
                className="text-2xl md:text-3xl lg:text-4xl text-white cursor-pointer"
                onClick={onClose}
              >
                &times;
              </button>
            </div>
            <div className="flex flex-col p-">
              <p className="text-white text-3xl font-normal font-jost">
                {lesson.modaltitle}
              </p>
              <ul className="list-disc pl-2 text-base font-inter text-white md:text-lg ">
                {lesson.modalcontent &&
                  lesson.modalcontent.split("\n").map((item, index) => (
                    <li key={index} className="list-inside">
                      {item.trim()}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
