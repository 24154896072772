import React from "react";
import EXK2 from "../images/Rectangle 884.png";
import EXK1 from "../images/Rectangle 885.png";

function Presentation() {
  return (
    <div id="presentation" className="mb-20 px-4 mx-auto max-w-screen-2xl">
      <div className="flex flex-col lg:flex-row mt-20">
        <div className="mb-4 lg:mr-10">
          <h1 className="text-neutral-400 text-lg font-normal font-inter whitespace-nowrap mt-4">
            — Біздің ұсынысымыз
          </h1>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="text-left p-4 md:w-1/2 lg:w-1/2">
            <img src={EXK2} alt="Гифка 1" loading="lazy" className="w-full" />
            <p className="mt-5 text-white text-xl font-normal font-inter">
              Біздің VR-сабақтар заманауи және қызықты сабақ <br /> беру
              тәжірибесін беріп, пәндік сабақтарды <br /> терең ұғынуға септігін
              тигізеді
            </p>
          </div>
          <div className="text-left p-4 md:w-1/2 lg:w-1/2">
            <img src={EXK1} alt="Гифка 2" loading="lazy" className="w-full" />
            <p className="mt-5 text-white text-xl font-normal font-inter">
              Шынайы сыныпта тұжырымдамалар мен эксперименттерді 
              көрсетуге арналған виртуалды <br /> сынып бағдарламалық
              жасақтамасы.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Presentation;
