import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const TimeChart = () => {
  const [timeData, setTimeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Статические данные для тестирования
    const staticUserVisits = {
      visit1: { timestamp: new Date("2024-01-31T10:00:00").toISOString() },
      visit2: { timestamp: new Date("2024-04-30T18:00:00").toISOString() },
      visit3: { timestamp: new Date("2024-02-29T20:00:00").toISOString() },
      visit4: { timestamp: new Date("2024-03-15T18:00:00").toISOString() },
      visit5: { timestamp: new Date("2024-05-20T22:30:00").toISOString() },

      // Добавьте больше статических посещений при необходимости
    };

    const fetchTimeData = () => {
      const hourlyVisits = {};

      // Извлекаем час из метки времени для каждого посещения
      Object.values(staticUserVisits).forEach((visit) => {
        const visitDate = new Date(visit.timestamp);
        const visitHour = visitDate.getHours();
        hourlyVisits[visitHour] = (hourlyVisits[visitHour] || 0) + 1;
      });

      // Подготавливаем данные для линейной диаграммы
      const chartData = {
        labels: Array.from({ length: 24 }, (_, i) => i),
        datasets: [
          {
            label: "Посещения",
            data: Array.from({ length: 24 }, (_, i) => hourlyVisits[i] || 0),
            fill: false,
            backgroundColor: "rgba(75,192,192,0.4)", // Цвет фона
            borderColor: "rgba(75,192,192,1)", // Цвет линии
            borderWidth: 2,
          },
        ],
      };

      setTimeData(chartData);
      setIsLoading(false);
    };

    fetchTimeData();
  }, []);

  return (
    <div className="bg-gradient-to-tl from-neutral-800 via-black to-stone-900 p-4 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4 text-white">
        Посещаемость по часам
      </h2>
      {isLoading ? (
        <p>Загрузка...</p>
      ) : (
        <Line
          data={timeData}
          options={{
            scales: {
              x: {
                type: "linear",
                position: "bottom",
                title: {
                  display: true,
                  text: "Час суток",
                  color:"#ffffff"
                },
              },
              y: {
                type: "linear",
                position: "left",
                title: {
                  display: true,
                  text: "Количество посещений",
                  color:"#ffffff"
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    var label = context.dataset.label || "";
                    if (label) {
                      label += ": ";
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y + " посещений";
                    }
                    return label;
                  },
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default TimeChart;
