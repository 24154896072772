import React, { useState, useEffect } from "react";
import { app } from "../firebase"; // Импорт по умолчанию
import { db, logVisit } from "../firebase"; // Import db and logVisit directly
import { ref, onValue } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { Bar } from "react-chartjs-2";
import { BarController, BarElement } from "chart.js";
import { Link } from "react-router-dom";

import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Title,
} from "chart.js";

import TimeChartKaraganda from "./TimeChartKaraganda";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  BarController,
  BarElement,
  Title
);

const Sidebar = ({ onSelectRegion }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const regions = [
    // "Абайская область",
    // "Акмолинская область",
    // "Актюбинская область",
    // "Алматинская область",
    "Атырауская область",
    // "Восточно-Казахстанская область",
    // "Жамбылская область",
    // "Жетысуская область",
    // "Западно-Казахстанская область",
    "Карагандинская область",

    // "Костанайская область",
    // "Кызылординская область",
    // "Мангистауская область",
    // "Павлодарская область",
    // "Северо-Казахстанская область",
    // "Туркестанская область",
    // "Улытауская область",
  ];

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="xl:w-1/6 lg:w-1/4 p-4 md:w-1/3 hidden   md:flex lg:flex flex-col  overflow-y-auto    ">
      <div className="flex items-center pl-2">
        <p className="text-xl  mb-4 font-bold text-left">MetaStudy</p>
        <p className="text-lg font-semibold mb-4 text-red-900">.</p>
      </div>
      <div className="px-2">
        <div className="relative inline-block w-full">
          <button
            className="bg-black text-white py-2.5 w-full rounded-xl font-bold"
            onClick={handleToggleDropdown}
          >
            {selectedRegion || "Карагандинская область"}
          </button>

          {isOpen && (
            <div className="w-full absolute top-full left-0 bg-white shadow-md border border-gray-200 rounded-b-md mt-2">
              <ul>
                {/* {regions.map((region) => (
                  <li
                    key={region}
                    onClick={() => handleSelectRegion(region)}
                    className="cursor-pointer p-3 text-[#6e7ca6] hover:text-[#000000] border-b border-gray-200"
                  >
                    {region}
                  </li>
                ))} */}
                <li className="cursor-pointer p-3 text-[#6e7ca6] hover:text-[#000000] border-b border-gray-200">
                  <Link to="/karaganda">Карагандинская область</Link>
                </li>
                <li className="cursor-pointer p-3 text-[#6e7ca6] hover:text-[#000000] border-b border-gray-200">
                  <Link to="/dashboard">Атырауская область</Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [userVisits, setUserVisits] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [userEmail, setUserEmail] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserEmail(user.email);
        const userVisitsRef = ref(db, `visits/${user.uid}`);

        onValue(userVisitsRef, (snapshot) => {
          setUserVisits(snapshot.val() || {});
          setIsLoading(false);
        });
      }
    });

    // Добавляем посещение при загрузке страницы, если пользователь авторизован
    if (!isLoading && userEmail) {
      logVisit();
    }

    return () => unsubscribe();
  }, [isLoading]);
  const regions = [
    // "Абайская область",
    // "Акмолинская область",
    // "Актюбинская область",
    // "Алматинская область",
    "Атырауская область",
    // "Восточно-Казахстанская область",
    // "Жамбылская область",
    // "Жетысуская область",
    // "Западно-Казахстанская область",
    "Карагандинская область",

    // "Костанайская область",
    // "Кызылординская область",
    // "Мангистауская область",
    // "Павлодарская область",
    // "Северо-Казахстанская область",
    // "Туркестанская область",
    // "Улытауская область",
  ];

  const getDynamicData = (length) => {
    const data = [];
  
    for (let i = 0; i < length; i++) {
      // Генерация случайных значений от 1 до 10
      const randomValue = Math.floor(Math.random() * (10 - 1 + 1) + 1);
      data.push(randomValue);
    }
  
    return data;
  };
  
  

  const getMonthlyChartData = () => {
    // Generate dynamically changing data for each day of the month
    const dynamicData = getDynamicData(31);
  
    // Forming the chart data
    const chartData = {
      labels: Array.from({ length: 31 }, (_, i) => i + 1),
      datasets: [
        {
          label: "Visits",
          data: dynamicData,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderWidth: 2,
        },
      ],
    };
  
    return chartData;
  };
  

  const chartData = getMonthlyChartData();
  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };
  const getTotalVisitsForMonth = (chartData, selectedMonth, selectedDay) => {
    const visitsData = chartData.datasets[0].data.slice(0, selectedDay);
    return visitsData.reduce((total, visits) => total + visits, 0);
  };
  
  const [totalVisits, setTotalVisits] = useState(0);


 useEffect(() => {
    // Calculate the total visits for the selected month
    const totalVisitsForSelectedMonth = getTotalVisitsForMonth(
      chartData,
      selectedMonth
    );
    setTotalVisits(totalVisitsForSelectedMonth);
  }, [selectedMonth, chartData]);


  useEffect(() => {
    // Calculate the total visits for the selected month and day
    const totalVisitsForSelectedDay = getTotalVisitsForMonth(
      chartData,
      selectedMonth,
      31 // Assuming the month has 31 days, adjust accordingly
    );
    setTotalVisits(totalVisitsForSelectedDay);
  }, [selectedMonth, chartData]);

  const handleSelectRegion = (region) => {
    // Handle region selection logic here
    console.log("Selected region:", region);
  };
  return (
    <div>
      <div className="bg-gradient-to-r from-neutral-300 via-neutral-100 to-neutral-400   text-black flex  min-h-screen ">
        <Sidebar onSelectRegion={handleSelectRegion} />

        <div className="w-full  bg-gradient-to-r from-neutral-300 via-neutral-100 to-neutral-400  ">
          <div className="flex items-center gap-10 p-2 ">
            <SidebarMobile
              regions={regions}
              onSelectRegion={handleSelectRegion}
            />
          </div>

          {isLoading ? (
            <center className="flex justify-center">
              {" "}
              <svg
                aria-hidden="true"
                class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>{" "}
            </center>
          ) : (
            <>
              <h1 className="text-2xl font-bold text-center text-black">
                Посещаемость сайта
              </h1>
              <div className="max-w-[32rem]  p-4">
                <div className="flex flex-col items-center shadow bg-gradient-to-tl from-neutral-800 via-black to-stone-900 rounded-lg text-white px-2">
                  {userEmail && (
                    <p className="text-lg font-bold my-4">
                      Пользователь: {userEmail}
                    </p>
                  )}

                  <div className="mb-4">
                    <div className="flex gap-4">
                      <select
                        className="bg-gradient-to-br from-gray-200 via-neutral-300 to-neutral-200 hover:bg-slate-100 text-black font-bold py-2 px-2 md:px-4 rounded transition-transform ease-in-out duration-200 whitespace-nowrap"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                          (month) => (
                            <option key={month} value={month}>
                              {new Date(null, month - 1).toLocaleString(
                                "default",
                                {
                                  month: "long",
                                }
                              )}
                            </option>
                          )
                        )}
                      </select>
                      <button
                        className="bg-gradient-to-br from-gray-200 via-neutral-300 to-neutral-200 hover:bg-gray-200 text-black font-bold py-2 px-6 rounded transition-transform ease-in-out duration-200"
                        onClick={logVisit}
                      >
                        Обновить
                      </button>
                    </div>
                  </div>
                  <div className="mb-2">
                  <p className="text-base mb-4 text-center">
              Общее количество посещений в выбранном месяце: {totalVisits}
            </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col lg:flex-row items-center p-4 gap-4">
                <div className="lg:w-3/4 w-full shadow">
                  {/* <BarChart chartData={getMonthlyChartData()} /> */}

                  <div className="bg-gradient-to-tl from-neutral-800 via-black to-stone-900 p-4 rounded-lg shadow-md mt-4">
                    <h2 className="text-xl font-bold mb-4 text-white">
                      Статистика по месяцам
                    </h2>
                    <div className="w-full mx-auto">
                      <Bar
                        data={chartData}
                        options={{
                          scales: {
                            x: {
                              type: "category", // Используем категориальную шкалу для месяцев
                              position: "bottom",
                              title: {
                                display: true,
                                text: "Месяц",
                                color: "#ffffff",
                              },
                            },
                            y: {
                              type: "linear",
                              position: "left",
                              title: {
                                display: true,
                                text: "Количество посещений",
                                color: "#ffffff",
                              },
                            },
                          },
                          plugins: {
                            tooltip: {
                              mode: "index",
                              callbacks: {
                                label: function (context) {
                                  var label = context.dataset.label || "";
                                  if (label) {
                                    label += ": ";
                                  }
                                  if (context.parsed.y !== null) {
                                    label += context.parsed.y + " посещений";
                                  }
                                  return label;
                                },
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="lg:w-1/2 w-full shadow">
                  <TimeChartKaraganda
                    userVisits={userVisits}
                    selectedMonth={selectedMonth}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SidebarMobile = ({ regions, onSelectRegion }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleSelectRegion = (region) => {
    onSelectRegion(region);
    setIsOpen(false);
  };

  return (
    <div className="md:hidden ">
      <button className="" onClick={handleToggleSidebar}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="26"
          height="26"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
      {/*  */}
      {/* Элемент, который будет служить задним фоном для остальных компонентов */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-30 blur-md"
          onClick={handleToggleSidebar}
        ></div>
      )}

      {/* Элемент, который будет служить задним фоном для остальных компонентов */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-30 blur-md"
          onClick={handleToggleSidebar}
        ></div>
      )}

      <div
        className={`transition-transform transform ${
          isOpen ? "translate-x-[-10px]" : "-translate-x-full"
        } absolute top-0 bg-gradient-to-r from-neutral-300 via-neutral-100 to-neutral-300 min-h-screen shadow-lg z-50 p-4`}
      >
        <div className="flex items-center flex-col">
          <img src="/img/dashboard_logo.png" className="mb-4"></img>
          <ul>
            {/* {regions.map((region) => (
              <li
                key={region}
                onClick={() => handleSelectRegion(region)}
                className="cursor-pointer p-3 text-[#6e7ca6] hover:text-[#000000] border-b border-gray-900"
              >
                {region}
              </li>
            ))} */}
            <li className="cursor-pointer p-3 text-[#6e7ca6] hover:text-[#000000] border-b border-gray-200">
              <Link to="/karaganda">Карагандинская область</Link>
            </li>
            <li className="cursor-pointer p-3 text-[#6e7ca6] hover:text-[#000000] border-b border-gray-200">
              <Link to="/dashboard">Атырауская область</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
//
export default Dashboard;
