import React, { useState } from "react";
import checkmark from "../svg/tick-circle_svgrepo.com.svg";
import checkmarkwhite from "../svg/checkmarkwhite.svg";
function PaymentCard() {
  const [quantity, setQuantity] = useState(1);
  const [quantity2, setQuantity2] = useState(1);
  const [quantity3, setQuantity3] = useState(1);
  const unitPrice = 758000;
  const unitPrice2 = 682200;
  const unitPrice3 = 606400;
  const updateTotalPrice = (newQuantity, setQuantity, unitPrice) => {
    if (newQuantity >= 0) {
      setQuantity(newQuantity);
    }
  };

  const totalPrice = unitPrice * quantity;
  const totalPrice2 = unitPrice2 * quantity2;
  const totalPrice3 = unitPrice3 * quantity3;
  return (
    <div className="mt-[150px] px-4 mx-auto items-center max-w-screen-2xl">
      <div id="tariff" className="flex flex-col lg:flex-row">
        <div className="text-center mb-4">
          <h1 className="text-neutral-400 text-lg font-medium  whitespace-nowrap mt-4">
            — Наши тарифы
          </h1>
        </div>
      </div>
      <div className="h-[0px] border border-neutral-400 "></div>

      <div className="lg:flex lg:flex-row flex-col items-center lg:gap-[25px]  xl:gap-[30px]  ">
        {/* 1ПАКЕТ */}
        <div className="bg-white rounded-lg mt-8 flex flex-col justify-between transform transition-transform duration-300 ease-in-out font-inter  w-full md:w-full lg:w-[470px]  hover:translate-y-[-20px]">
          <div>
            <h1 className="text-black text-2xl font-medium p-4">
              Цена до 70 лабораторий
            </h1>
            <div className="h-[0px] border border-black"></div>
            <div className="flex-col p-4 items-center">
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Гарнитура Oculus Quest 2
                  </p>
                </div>
              </div>
              {/* <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Практические задания по Истории: 30
                  </p>
                </div>
              </div> */}

              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Доставка до вашего учреждения
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Настройка виртуальных очков
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Обучение персонала по использованию
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-lg text-[#8E8E8E] p-4">Цена</h2>
            <p className="text-3xl font-bold text-black mb-6 p-4">
              {totalPrice}тг/шт
            </p>

            <div className="flex flex-col md:flex-row md:justify-between items-center mb-4 p-4">
              <div className="flex items-center border border-black text-black rounded-lg mb-2 md:mb-0 p-1 ">
                <button
                  className="p-1 font-bold text-2xl -mt-1"
                  onClick={() => {
                    if (quantity > 1) {
                      updateTotalPrice(quantity - 1, setQuantity, unitPrice);
                    }
                  }}
                >
                  -
                </button>
                <span className="ml-2">Кол-во очков:</span>
                <input
                  type="text"
                  className="ml-2 text-center w-12  bg-transparent border border-gray-400 rounded"
                  value={quantity}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10);
                    updateTotalPrice(newQuantity, setQuantity, unitPrice);
                  }}
                />
                <button
                  className="p-1 font-bold text-2xl -mt-1"
                  onClick={() => {
                    updateTotalPrice(quantity + 1, setQuantity, unitPrice);
                  }}
                >
                  +
                </button>
              </div>

              <button className="w-full md:w-auto mt-4 md:mt-0 px-6 py-2 bg-black text-white rounded-lg">
                 <a
                  href="https://wa.me/+77073845529"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-auto mt-4 md:mt-0 px-6 py-2 bg-black text-white rounded-lg"
                >
                 Заказать
                </a>
              </button>
            </div>
          </div>
        </div>
        {/* 2ПАКЕТ */}
        <div className="bg-[#005AC5]  rounded-lg mt-8 flex flex-col justify-between transform transition-transform duration-300 font-inter ease-in-out w-full md:w-full lg:w-[470px]  hover:translate-y-[-20px]">
          <div>
            <h1 className="text-white text-2xl font-medium p-4">
              Цена до 100 лабораторий
            </h1>
            <div className="h-[0px] border border-white"></div>
            <div className="flex-col p-4 items-center">
              <div className="mb-4">
                <div className="flex items-center gap-4">
                  <img src={checkmarkwhite} alt="Checkmark" />
                  <p className="text-white text-lg font-medium">
                    Гарнитура Oculus Quest 2
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex items-center gap-4">
                  <img src={checkmarkwhite} alt="Checkmark" />
                  <p className="text-white text-lg font-medium">
                    Практические задания по Физике: 68
                  </p>
                </div>
              </div>
              {/* <div className="mb-4">
                <div className="flex items-center gap-4">
                  <img src={checkmarkwhite} alt="Checkmark" />
                  <p className="text-white  text-lg font-medium">
                    Практические задания по Истории: 30
                  </p>
                </div>
              </div> */}
              <div className="mb-4">
                <div className="flex items-center gap-4">
                  <img src={checkmarkwhite} alt="Checkmark" />
                  <p className="text-white text-lg font-medium">
                    Доставка до вашего учреждения
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex items-center gap-4">
                  <img src={checkmarkwhite} alt="Checkmark" />
                  <p className="text-white text-lg font-medium">
                    Настройка виртуальных очков
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex items-center gap-4">
                  <img src={checkmarkwhite} alt="Checkmark" />
                  <p className="text-white text-lg font-medium">
                    Обучение персонала по использованию
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-lg text-white p-4">Цена</h2>
            <p className="text-3xl font-bold text-white mb-4 p-4">
              {totalPrice2}тг/шт
            </p>

            <div className="flex flex-col md:flex-row md:justify-between items-center mb-4 p-4">
              <div className="flex items-center border border-white text-white rounded-lg mb-2 p-1 md:mb-0">
                <button
                  className="p-1 font-bold text-2xl -mt-1"
                  onClick={() => {
                    if (quantity2 > 1) {
                      updateTotalPrice(quantity2 - 1, setQuantity2, unitPrice2);
                    }
                  }}
                >
                  -
                </button>
                <span className="ml-2 ">Кол-во очков:</span>
                <input
                  type="text"
                  className="ml-2 text-center w-12  bg-transparent border border-white rounded"
                  value={quantity2}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10);
                    updateTotalPrice(newQuantity, setQuantity2, unitPrice2);
                  }}
                />

                <button
                  className="p-1 font-bold text-2xl -mt-1"
                  onClick={() => {
                    updateTotalPrice(quantity2 + 1, setQuantity2, unitPrice2);
                  }}
                >
                  +
                </button>
              </div>
              <button className="w-full md:w-auto mt-4 md:mt-0 px-6 py-2 bg-white text-black rounded-lg">
                 <a
                  href="https://wa.me/+77073845529"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-auto mt-4 md:mt-0 px-6 py-2 bg-white text-black rounded-lg"
                >
                 Заказать
                </a>
              </button>
            </div>
          </div>
        </div>
        {/* 3ПАКЕТ */}
        <div className="bg-white rounded-lg mt-8 flex flex-col justify-between transform transition-transform duration-300 ease-in-out font-inter  w-full md:w-full lg:w-[470px]  hover:translate-y-[-20px]">
          <div>
            <h1 className="text-black text-2xl font-medium p-4">
              Цена до 150 лабораторий
            </h1>
            <div className="h-[0px] border border-black"></div>
            <div className="flex-col p-4 items-center">
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Гарнитура Oculus Quest 2
                  </p>
                </div>
              </div>
              {/* <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Практические задания по Истории: 30
                  </p>
                </div>
              </div> */}

              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Доставка до вашего учреждения
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Настройка виртуальных очков
                  </p>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <img src={checkmark} alt="Checkmark" />
                  <p className="text-black  text-lg font-medium">
                    Обучение персонала по использованию
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="text-lg text-[#8E8E8E] p-4">Цена</h2>
            <p className="text-3xl font-bold text-black mb-6 p-4">
              {totalPrice3}тг/шт
            </p>

            <div className="flex flex-col md:flex-row md:justify-between items-center mb-4 p-4">
              <div className="flex items-center border border-black text-black rounded-lg mb-2 md:mb-0 p-1 ">
                <button
                  className="p-1 font-bold text-2xl -mt-1"
                  onClick={() => {
                    if (quantity3 > 1) {
                      updateTotalPrice(quantity3 - 1, setQuantity3, unitPrice3);
                    }
                  }}
                >
                  -
                </button>
                <span className="ml-2">Кол-во очков:</span>
                <input
                  type="text"
                  className="ml-2 text-center w-12  bg-transparent border border-gray-400 rounded"
                  value={quantity3}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10);
                    updateTotalPrice(newQuantity, setQuantity3, unitPrice3);
                  }}
                />
                <button
                  className="p-1 font-bold text-2xl -mt-1"
                  onClick={() => {
                    updateTotalPrice(quantity3 + 1, setQuantity3, unitPrice3);
                  }}
                >
                  +
                </button>
              </div>

              <button className="w-full md:w-auto mt-4 md:mt-0 px-6 py-2 bg-black text-white rounded-lg">
                 <a
                  href="https://wa.me/+77073845529"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full md:w-auto mt-4 md:mt-0 px-6 py-2 bg-black text-white rounded-lg"
                >
                 Заказать
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentCard;
