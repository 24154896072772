import Main from "../cards/Main"
import Footer from "../Layouts/Footer"
import Whowithus from "../cards/Whowithus"
import Equipment from "../cards/Equipment"
import Presentation from "../cards/Presentation"
import Questions from "../cards/Questions"
import Lessons from "../cards/Lessons"
import PaymentCard from "../cards/PaymentCard"
import Contact from "../cards/Contact"
import ScrollToTop from "react-scroll-to-top"
import { Element } from "react-scroll";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: "-100%" },
};
const transition = {
  duration: 2,
};
function FinalAppKaz() {
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [ref5, inView5] = useInView();
  const [ref6, inView6] = useInView();
  return (
    <div>
    <section className="">
      <Main />
    </section>

    <motion.section
      ref={ref1}
      initial={{ opacity: 0, x: -100 }}
      animate={inView1 ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1 }}
    >
      <Presentation />
    </motion.section>
    <section className="">
      {" "}
      <Whowithus />
    </section>

    {/* <Equipment2 /> */}
    <motion.section
      className=""
      ref={ref6}
      initial={{ opacity: 0, y: -100 }}
      animate={inView6 ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
    >
      <Element name="equipment">
        <Equipment />
      </Element>
    </motion.section>
    <motion.section
      ref={ref2}
      initial={{ opacity: 0, x: -100 }}
      animate={inView2 ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1 }}
    >
      <Lessons />
    </motion.section>

    <motion.section
      ref={ref3}
      initial={{ opacity: 0, x: -100 }}
      animate={inView3 ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1 }}
    >
      <PaymentCard />
    </motion.section>
    <motion.section
      ref={ref4}
      initial={{ opacity: 0, x: -100 }}
      animate={inView4 ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1 }}
    >
      <Questions />
    </motion.section>
    <motion.section
      ref={ref5}
      initial={{ opacity: 0, x: -100 }}
      animate={inView5 ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 1 }}
    >
      <Contact />
    </motion.section>
    <Footer />
    <ScrollToTop
      smooth={true}
      top={20}
      className="items-center flex px-2 animate-bounce  "
      color="black"
    />
  </div>
  )
}

export default FinalAppKaz