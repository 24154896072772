import { useState, React } from "react";
import gif1 from "../GIF/gif1.gif";
import gif2 from "../GIF/gif2.gif";
import gif3 from "../GIF/gif3.gif";
import gif4 from "../GIF/gif4.gif";
import gif5 from "../GIF/gif5.gif";
import gif6 from "../GIF/gif6.gif";
import gifhs1 from "../GIF/gifhs1.gif";
import gifhs2 from "../GIF/gifhs2.gif";
import gifhs3 from "../GIF/gifhs3.gif";
import gifhs4 from "../GIF/gifhs4.gif";
import gifhs5 from "../GIF/gifhs5.gif";
import gifhs6 from "../GIF/gifhs6.gif";
import gifhs7 from "../GIF/gifhs7.gif";
import gifhs8 from "../GIF/gifhs8.gif";
import "../CSS/main.css";
function Lessons() {
  const physicsLessons = [
    // механические явления
    {
      title: `Механикалық құбылыстар
      `,
      description:
        "Механикалық құбылыстар: физикадағы қозғалыс, күш және энергия негіздері.",
      imgSrc: gif1,
      modaltitle: "Механические явления",
      modalcontent: ` Салмақ
      Архимед күші
      Динамикалық деформация
      Ньютон заңы 1
      Ньютон заңы 2
      Ньютон заңы 3
      Бал Паскаль
      Ньютон түтігі
      Бірқалыпты қозғалыс кезіндегі дененің үдеуін анықтау.
      Қалыпты, орташа прогрессивті және деградациялық қозғалыс.
      Сорғының жұмыс принципі
      Контактілі ыдыстар
      Қысым
      Барометрдің жұмыс принципі
      энергияның сақталу заңы
      иық тепе-теңдігі жағдайын анықтау
      үйкеліс күші
      лақтырылған дененің қиғаш және бұрышпен қозғалу траекториясы
      инерция заңы
      қарапайым қозғалыстар
      математикалық маятник,
      қатты дененің массасы мен тығыздығын анықтау
      Су ішіндегі салмағы 200 г денеге әсер ететін орын ауыстыру күшін анықтау
      дене параметрлерін анықтау
      Гидравликалық пресс.`,
    },
    //  Световые явления
    {
      title: `Жылу құбылыстары
      `,
      description:
        "Жылу құбылыстары-жылуды және оның материалдарға әсерін зерттеу.",
      modalcontent: `Изохора
      Изобара
      Изотермия
      жылу өткізгіштік
      температура бірліктері
      бу машинасы
      қозғалтқыш
      әртүрлі температурадағы суды араластыру кезіндегі жылу мөлшерін салыстыру.
      мұздың таралуы`,
      imgSrc: gif5,
    },

    // Электрические явления
    {
      title: `Электрлік құбылыс
      `,
      description:
        "Электрлік құбылыстар-электр зарядтарының қозғалысы мен өзара әрекеттесуіне байланысты.",
      modalcontent: `Генератордың жұмыс принципі
  Аттас әрбір зарядтау органының әрекеті
  Зарядталған органдардың әрекеті
  Тізбек бөлігі үшін Ом заңын зерттеу
  Реостат арқылы ағымдағы реттеу
  Электр тізбегінің әртүрлі бөліктеріндегі кернеуді өлшеу
  Амперметр мен вольтметрдің көмегімен өткізгіш кедергісін өлшеу
  Өткізгіштік кедергісін анықтау
  Электроскоптың құрылғысы және жұмыс істеу принципі
  Электр зарядының сақталу заңы
  Электр жылытқышы бар қондырғының ПӘК-ін өлшеу
  Денені электрлендіру
  Көлеміне байланысты металл кедергісін анықтау
      `,
      imgSrc: gif3,
    },
    // Электро-магнитные явления
    {
      title: `Электромагниттік құбылыстар`,
      description: `Электромагниттік қабылыстар-өрістердің арасындағы әрекеті мен оларды технологиядағы рөлін зерттеу.`,
      imgSrc: gif4,
      modalcontent: `Трансформатордың жұмыс істеу принципі
      Ленц позициясы
      Электромагниттік индукция құбылысын зерттеу
      Генератордың құрылғысы
      Тұрақты ток генераторының конструкциясы
      Электролиздегі электр тогының пайда болуы
      Үш фазалы электр тогы
      Попова радиосы`,
    },
    // Тепловые явления
    {
      title: `Жарық құбылыстар
      `,
      description: `
      Жарық құбылыстары-жарықты, оптиканы және оның әсерін зерттеу.`,

      imgSrc: gif2,

      modalcontent: `Сыну бұрышының жарықтың түсу бұрышына тәуелділігін зерттеу
      Негізгі фокустық қашықтықтарды және оптикалық күш жинау линзаларын өлшеу
      Призма арқылы жарық дисперсиясын бақылау
      Тікелей жарықтың диффузиясы
      Жинау линзасындағы сәулелер
      Диффузды линзалардағы сәулелер
      Проекциялық аппарат пен камераның жұмыс істеу принципі
      Көз моделі бар көзілдіріктегі, бүріккіш линзалардағы және құрастырмалы линзалардағы кескінді көруді зерттеу.`,
    },
    {
      title: "Физикалық құбылыстар",
      modaltitle: "Физикалық құбылыстар",
      description:
        "Физикалық құбылыстар-бұл кейбір заттардың басқаларына айналмайтын құбылыстар.",

      imgSrc: gif6,

      modalcontent: `1. Физикалық құрылғылармен танысу.
      2. Тең ұққысында массасын салысу
          Толық тасымалдар
          Жылы тасымалдар
      3. Астрофизика
          Жердің характері
          Жупитерді сипаттау
          Сатурнды сипаттау
          Марсты сипаттау
          Венера
          Уран
          Нептун
      4. Кіші тас аудармасының өлшемі`,
    },
  ];

  const historyLessons = [
    {
      title: "Қазақстандағы ескерімді адамдардың тіршіліктері",
      // description: "Тарих дәуерлері:",
      imgSrc: gifhs1,
      modalcontent: `Тас дәуірі (2,5 млн - 2800 ж.б.)
          Мыс дәуірі (2-1 мың ж.б.)`,
    },
    {
      title: "Темір дәуірі",
      // description: "Сак, Усуні, Кангуы, Гунндер",
      imgSrc: gifhs2,
      modalcontent: `Сак (VIII–III ж.м. б.),
          Усуні (IV ж. б. - III ж.м. б.), Кангуы (III ж. б. - IV ж.м. б.), Гунндер (III-II ж.м. б. - V ж.м. б.)`,
    },
    {
      title: "Қазақстан тарихындағы түрік дәуірі",
      // description:
      //   "Түрік хағанаты, Батыс Түрік және Шығыс Түрік хағанаттары, Түрік Түрғеш хағанаты",
      imgSrc: gifhs3,
      modalcontent: `Түрік хағанаты (552-603ж.ж.)
         Батыс Түрік (603-704ж.ж.) және Шығыс Түрік (682-744ж.ж.) хағанаттары
          Түрік Түрғеш хағанаты (704-940ж.ж.)
          Атлаус тайға (751ж.ж.)
         Қарлуқ хағанаты (756-940ж.ж.)
           Оғуз халықтарының мемлекеті (ІХ ж. басы - ХІ ғ. ортасы)
           Қымык хағанаты (ІХ ж. басы - ХІ ғ. ортасы)`,
    },
    {
      title: "ХІ - ХІІІ ғасырларда Қазақстан",
      // description:
      //   "Қараханид халықтарының мемлекеті, Қарақытай халықтарының мемлекеті, Наймандар, Керейлер, Жалаирлар, Қыпшақ хандығы",
      imgSrc: gifhs5,
      modalcontent: `Қараханид халықтарының мемлекеті (942-1212жж.)
          Қарақытай халықтарының мемлекеті (1128-1212жж.)
      Наймандар, Керейлер, Жалаирлар (ХІІ - ХІІІ ж. алғашқы жарымы)
        Қыпшақ хандығы (ХІ - ХІІІ ж. алғашқы жарымы)`,
      modalcontent: `Қараханид халықтарының мемлекеті (942-1212жж.)
         Қарақытай халықтарының мемлекеті (1128-1212жж.)
       Наймандар, Керейлер, Жалаирлар (ХІІ - ХІІІ ж. алғашқы жарымы)
        Қыпшақ хандығы (ХІ - ХІІІ ж. алғашқы жарымы)`,
    },
    {
      title: "Х - ХІІІ ғасырлардағы Қазақстан қаласы",
      // description: "Қала даму және қала орнын жасау",
      imgSrc: gifhs6,
      modalcontent: `Қала даму және қала орнын жасау (ХІ - ХІІІ ж. алғашқы жарымы)`,
    },
    {
      title: "ХІІІ-ХV ғасырлардағы Қазақстан",
      // description:
      //   "Моғолдық қозғалыс Қазақстан жерінде, Ақ Орда, Моғулистан, Темір хандар және теміриддердің мемлекеті, Абулхайыр хандығы, Ногай Орда",
      imgSrc: gifhs4,
      modalcontent: `Моғолдық қозғалыс Қазақстан жерінде.
          Ақ Орда (ХІІІ жасынан бастап ХV ғасырлар)
           Моғулистан (Орталық ХІV жасы - ХVІн жасының басы)
           Темір хандар және теміриддердің мемлекеті
           Абулхайыр хандығы
          Ногай Орда`,
    },
    {
      title: "ХV-ХVІІ ғасырлардағы Қазақ хандығы",
      // description: "Қазақ хандығын құру (ХV ғ. - ХVІ жасының басы)",
      imgSrc: gifhs7,
      modalcontent: `Қасым хандығы (1511 – 1518 жж.)
           Хакназар хандығы (1538-1580 жж.)
           Тауекел хандығы (1583 – 1598 жж.)
           Есім хандығы (1598 -1628 жж.)
           Жангир хандығы (1529 -1652 жж.)
         Тауке хандығы (1680 – 1715 ж.м.)`,
    },
    {
      title: "ХV-XVІІ ғасырлардағы Қазақ әдебиеті",
      // description: "Қазақ халқының жатақханалары, өнеркәсіп",
      imgSrc: gifhs8,
      modalcontent: `Қазақ халқының жатақханалары
         Өнеркәсіп`,
    },
  ];

  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const openModal = (index, type) => {
    setSelectedCardIndex({ index, type });
  };

  const closeModal = () => {
    setSelectedCardIndex(null);
  };

  const renderLessonCards = (lessons, type) => {
    return lessons.map((lesson, index) => (
      <div
        key={index}
        className="border border-gray-700 rounded-lg shadow hover:border-[#005AC5] "
      >
        <div className="image-container">
          <img
            loading="lazy"
            className="rounded-t-lg"
            src={lesson.imgSrc}
            alt=""
          />
          <div className="replacement-div px-4">
            <p className="text-white text-2xl font-semibold font-jost leading-[30px] mb-1 white ">
              {lesson.title}
            </p>
            <p className="text-white text-base font-inter  font-medium  description-mobile hidden lg:block lg:h-[125px] h-[110px]">
              {lesson.description}
            </p>
            <button
              onClick={() => openModal(index, type)}
              className="px-4 py-2 bg-neutral-100 rounded-[200px] border border-white justify-center items-center gap-2.5 absolute bottom-4 left-4"
              style={{ zIndex: 1 }}
            >
              <span className="text-[14px]  font-medium px-2 py-1 text-black font-inter">
                Толығырақ
              </span>
            </button>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div
      id="lessons"
      className="px-4 mt-24 p-2 mx-auto items-center max-w-screen-2xl "
    >
      {/* Первый аккордеон */}
      <div id="physics">
        <div className="mb-4">
          <h1 className="text-neutral-400 text-lg font-normal font-inter whitespace-nowrap mt-4">
            — Физика практикасы
          </h1>
        </div>
        <div className="h-[0px] border border-neutral-400"></div>
        <div className=" mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {renderLessonCards(physicsLessons, "physics")}
        </div>
      </div>

      {/* Второй аккордеон */}
      {/* <div id="history">
        <div className="mb-4">
          <h1 className="text-neutral-400 text-lg font-normal font-inter whitespace-nowrap mt-4">
            — История практикасы
          </h1>
        </div>
        <div className="h-[0px] border border-neutral-400 mb-4 mt-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {renderLessonCards(historyLessons, "history")}
        </div>
      </div> */}

      {selectedCardIndex !== null && (
        <Modal
          lesson={
            selectedCardIndex.type === "physics"
              ? physicsLessons[selectedCardIndex.index]
              : historyLessons[selectedCardIndex.index]
          }
          onClose={closeModal}
        />
      )}
    </div>
  );
}
const Modal = ({ lesson, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center z-50 animate-fadeIn">
      <div
        className="w-full h-full bg-gray-900 opacity-75"
        onClick={onClose}
      ></div>
      <div className="fixed top-0 right-0 h-full z-50">
        <div className="bg-black border border-[#005AC5]rounded-md shadow-md max-w-lg p-4 md:p-8 lg:p-10 h-full">
          <div
            className="p-4 modal-scroll"
            style={{ maxHeight: "100vh", overflowY: "auto" }}
          >
            <div className="flex justify-end">
              <button
                className="text-2xl md:text-3xl lg:text-4xl text-white cursor-pointer"
                onClick={onClose}
              >
                &times;
              </button>
            </div>
            <div className="flex flex-col p-">
              <p className="text-white text-3xl font-normal font-jost">
                {lesson.modaltitle}
              </p>
              <ul className="list-disc pl-2 text-base font-inter text-white md:text-lg">
                {lesson.modalcontent &&
                  lesson.modalcontent.split("\n").map((item, index) => (
                    <li key={index} className="list-inside">
                      {item.trim()}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
