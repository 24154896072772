import { useState, React } from "react";
import gif1 from "../GIF/gif1.gif";
import gif2 from "../GIF/gif2.gif";
import gif3 from "../GIF/gif3.gif";
import gif4 from "../GIF/gif4.gif";
import gif5 from "../GIF/gif5.gif";
import gif6 from "../GIF/gif6.gif";
import gifhs1 from "../GIF/gifhs1.gif";
import gifhs2 from "../GIF/gifhs2.gif";
import gifhs3 from "../GIF/gifhs3.gif";
import gifhs4 from "../GIF/gifhs4.gif";
import gifhs5 from "../GIF/gifhs5.gif";
import gifhs6 from "../GIF/gifhs6.gif";
import gifhs7 from "../GIF/gifhs7.gif";
import gifhs8 from "../GIF/gifhs8.gif";
import more from "../images/more.png"
import "../CSS/adaptive.css";
function Lessons() {
  const physicsLessons = [
    // mechanical phenomena
    {
      title: "Mechanical phenomena",
      description:
        "Mechanical Phenomena: Fundamentals of Motion, Forces and Energy in Physics.",
      imgSrc: gif1,
      modaltitle: "Mechanical phenomena",
      modalcontent: ` Mass
       Archimedes power
       Dynamic deformation
     Newton's Law 1
      Newton's Law 2
       Newton's Law 3
       Ball Pascal
       Newtonian tube
       Determination of the acceleration of a body during uniform motion.
      Normal, moderately progressive and degradative movement.
       Pump operating principle
       Contact utensils
       Pressure
      How the Barometer works
      Law of energy conservation
      Determination of shoulder balance conditions
      Friction force
     Trajectory of movement of the thrown body with oblique and angle
      Law of Inertia
      Simple moves
       Math pendulum
     Determination of mass and density of a solid
     Determination of the displacement force acting on a body of mass 200 g inside water
       Determination of body parameters
       Hydraulic press.`,
    },
    // Light phenomena
    {
      title: "Thermal phenomena",
      description:
        "Thermal phenomena - the study of heat and its effects on materials.",
      modalcontent: `Isochore
      Isobar
      Isothermy
      Thermal conductivity
      Temperature units
      Steam engine
      Engine
      Comparison of the amount of heat when mixing water at different temperatures.
      Ice reproduction`,
      imgSrc: gif5,
    },

    // Electrical phenomena
    {
      title: "Electrical phenomena",
      description:
        "Mechanical Phenomena: Fundamentals of Motion, Forces and Energy in Physics.",
      modalcontent: `Generating electricity from wind power
      Receiving electricity from solar power
      Generator Van de Graaf
      Generator operating principle
      The action of each charging body of the same name
      Action of charged bodies
      Study of Ohm's Law for Part of a Circuit
      Current regulation by rheostat
      Measuring voltage in various parts of an electrical circuit
      Measuring conductor resistance using an ammeter and voltmeter
      Determination of conductivity resistance
      Design and principle of operation of an electroscope
      Law of conservation of electric charge
      Measuring the efficiency of an installation with an electric heater
      Electrification of the body
      Determination of metal resistance depending on volume`,
      imgSrc: gif3,
    },
    // Electro-magnetic phenomena
    {
      title: "Electro-magnetic phenomena",
      description:
        "Light phenomena - the study of light, optics and its effects.",
      imgSrc: gif4,
      modalcontent: `The principle of operation of a transformer
      Lenz position
      Study of the phenomenon of electromagnetic induction
      Alternating current generator device
      DC generator design
      Generation of electric current in electrolysis
      Three-phase electric current
      Radio Popova`,
    },
    // Thermal phenomena
    {
      title: "Light phenomena",
      description:
        "Light phenomena - the study of light, optics and its effects.",

      imgSrc: gif2,
      modaltitle: "Light phenomena",
      modalcontent: `Study of the dependence of the angle of light arrival
      Study of the dependence of the breakage angle on the angle of incidence of light
      Measuring main focal lengths and optical force collection lenses
      Controlling light dispersion using a prism
      Direct Light Spread
      Rays in a collecting lens
      Rays in diffuse lenses
      The principle of operation of the projection apparatus and camera
      Study of image vision in glasses with an eye model, spray lenses and prefabricated lenses.`,
    },
    {
     title:"Physical phenomena",
      imgSrc: gif6,
      modaltitle: "Physical phenomena",
      description:
      "Physical phenomena are phenomena in which the transformation of some substances into others does not occur.",
      modalcontent:`1. Introduction to physical devices.
      2. Comparison of body weight in equal volume
      Solid bodies
      Liquid bodies
      3. Astrophysics
      Characteristics of the Earth's planet
      Description of the planet Jupiter
      Description of the planet Saturn
      Description of the planet Mars
      Venus
      Uranium
      Neptune
      4. Determining the size of small bodies `,
    },
  ];

  const historyLessons = [
    {
      title: "Life of Ancient People in Kazakhstan",
      // description: "History Eras:",
      imgSrc: gifhs1,
      modalcontent: `Stone Age (2.5 million – 2800 BCE)
        Bronze Age (2-1 thousand BCE)`,
    },
    {
      title: "Iron Age",
      // description: "Saka, Usuni, Kangyui, Huns",
      imgSrc: gifhs2,
      modalcontent: `Saka (8th–3rd centuries BCE)
        Usuni (4th century BCE–3rd century CE), Kangyui (3rd century BCE–4th century CE), Huns (3rd–2nd centuries BCE–5th century CE)`,
    },
    {
      title: "Kazakhstan's History in the Turkic Period",
      // description:
      //   "Turkic Khaganate, Western Turkic and Eastern Turkic Khaganates, Turkic Türgesh Khaganate",
      imgSrc: gifhs3,
      modalcontent: `Turkic Khaganate (552-603 CE)
       Western Turkic (603-704 CE) and Eastern Turkic (682-744 CE) Khaganates
        Türgesh Khaganate (704-940 CE) 
        Battle of Talas (751 CE)
       Karluk Khaganate (756-940 CE)
         Oghuz State (early 9th – mid-11th centuries)
         Kimek Khaganate (early 9th – mid-11th centuries)`,
    },
    {
      title: "Kazakhstan in the Mid-10th to Early 13th Century",
      // description:
      //   "Karahanids, Karakitais, Naimans, Keraites, Jalairs, Kipchak Khanate",
      imgSrc: gifhs5,
      modalcontent: `Karahanids (942-1212 CE) 
        Karakitais (1128-1212 CE)
        Naimans, Keraites, Jalairs (early 12th – 13th centuries)
        Kipchak Khanate (early 11th – 13th centuries)`,
    },
    {
      title: "Kazakhstan's Culture in the 11th to Early 13th Century",
      // description: "Urban Development and City Planning",
      imgSrc: gifhs6,
      modalcontent: `Urban Development and City Planning (early 11th – 13th centuries)`,
    },
    {
      title: "Kazakhstan in the 13th-15th Centuries",
      // description:
      //   "Mongol Conquest in Kazakhstan, White Horde, Moghulistan, Timurid States, Abu'l-Khayr Khanate, Nogai Horde",
      imgSrc: gifhs4,
      modalcontent: `Mongol Conquest in Kazakhstan.
        White Horde (13th–early 15th centuries)
         Moghulistan (mid-14th – early 16th century)
         Timurid States
         Abu'l-Khayr Khanate
        Nogai Horde`,
    },
    {
      title: "Kazakh Khanate in the 15th-17th Centuries",
      // description:
      //   "Formation of the Kazakh Khanate (15th century – early 16th century)",
      imgSrc: gifhs7,
      modalcontent: `Kasym Khan (1511 – 1518 CE)
         Haknazar Khan (1538-1580 CE)
         Taukel Khan (1583 – 1598 CE)
         Esim Khan (1598 -1628 CE)
         Zhangir Khan (1529 -1652 CE)
       Tawke Khan (1680 – 1715 CE)`,
    },
    {
      title: "Kazakh Culture in the 15th-17th Centuries",
      // description: "Dwellings of the Kazakh People, Crafts",
      imgSrc: gifhs8,
      modalcontent: `Dwellings of the Kazakh People
       Crafts`,
    },
  ];

  const [selectedCardIndex, setSelectedCardIndex] = useState(null);

  const openModal = (index, type) => {
    setSelectedCardIndex({ index, type });
  };

  const closeModal = () => {
    setSelectedCardIndex(null);
  };

  const renderLessonCards = (lessons, type) => {
    return lessons.map((lesson, index) => (
      <div
      key={index}
      className="border border-gray-700 rounded-lg shadow hover:border-[#005AC5] "
    >
      <div className="image-container">
        
        <img
          loading="lazy"
          className="rounded-t-lg"
          src={lesson.imgSrc}
          alt=""
        />
        <div className="replacement-div p-4">
          <p className="text-white text-2xl font-semibold font-jost leading-[30px] mb-1">
            {lesson.title}
          </p>
          <p className="text-white text-base font-inter  font-medium  description-mobile hidden lg:block lg:h-[125px] h-[110px]">
            {lesson.description}
          </p>
          <button
            onClick={() => openModal(index, type)}
            className="px-4 py-2 bg-neutral-100 rounded-[200px] border border-white justify-center items-center gap-2.5 absolute bottom-4 left-4"
            style={{ zIndex: 1 }}
          >
            <span className="text-[14px]  font-medium px-2 py-1 text-black font-inter">
              Show more
            </span>
          </button>
        </div>
      </div>
    </div>
    ));
  };

  return (
    <div
      id="lessons"
      className="px-4 mt-24 p-2 mx-auto items-center max-w-screen-2xl "
    >
      {/* Первый аккордеон */}
      <div id="physics">
        <div className="mb-4">
          <h1 className="text-neutral-400 text-lg font-normal font-['Inter'] whitespace-nowrap mt-4">
            — Practical lessons in physics
          </h1>
        </div>
        <div className="h-[0px] border border-neutral-400"></div>
        <div className=" mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {renderLessonCards(physicsLessons, "physics")}
        </div>
      </div>

      {/* Второй аккордеон
      <div id="history">
        <div className="mb-4">
          <h1 className="text-neutral-400 text-lg font-normal font-['Inter'] whitespace-nowrap mt-4">
            — Practical lessons in history
          </h1>
        </div>
        <div className="h-[0px] border border-neutral-400 mb-4 mt-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {renderLessonCards(historyLessons, "history")}
        </div>
      </div> */}

      {selectedCardIndex !== null && (
        <Modal
          lesson={
            selectedCardIndex.type === "physics"
              ? physicsLessons[selectedCardIndex.index]
              : historyLessons[selectedCardIndex.index]
          }
          onClose={closeModal}
        />
      )}
    </div>
  );
}
const Modal = ({ lesson, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center z-50 animate-fadeIn">
      <div
        className="w-full h-full bg-gray-900 opacity-75"
        onClick={onClose}
      ></div>
      <div className="fixed top-0 right-0 h-full z-50">
        <div className="bg-black border border-[#005AC5] rounded-md shadow-md max-w-lg p-4 md:p-8 lg:p-10 h-full">
          <div
            className="p-4"
            style={{ maxHeight: "100vh", overflowY: "auto" }}
          >
            <div className="flex justify-end">
              <button
                className="text-2xl md:text-3xl lg:text-4xl text-white cursor-pointer"
                onClick={onClose}
              >
                &times;
              </button>
            </div>
            <div className="flex flex-col p-">
              <p className="text-white text-4xl font-normal font-['Jost']">
                {lesson.modaltitle}
              </p>
              <ul className="list-disc pl-2 text-base text-white md:text-lg">
                {lesson.modalcontent &&
                  lesson.modalcontent.split("\n").map((item, index) => (
                    <li key={index} className="list-inside">
                      {item.trim()}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
