// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthPage from "./components/AuthPage";
import RegisterForm from "./components/RegisterForm";
import { auth } from "./components/firebase";
import "./components/locales/ru/CSS/adaptive.css";
import FinalApp from "./components/locales/ru/FinalComponents/FinalApp";
import FinalAppEn from "./components/locales/en/FinalApp/FinalAppEn";
import FinalAppKaz from "./components/locales/kaz/FinalComponents/FinalApp";
import DashBoard from "./components/Dashboard.js";
import Karaganda from "./components/oblasti/Karaganda.js";

function App() {
  const [authState, setAuthState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        // Check if authentication has already been performed
        if (authChecked) {
          return;
        }

        console.log("Checking auth state...");
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Simulating a 3-second delay
        const user = null; // Replace null with the actual user object or null if not authenticated

        setAuthState(user);
        setLoading(false);
        setAuthChecked(true);
      } catch (error) {
        console.error("Error checking auth state:", error);
        setLoading(false);
      }
    };

    checkAuthState();
  }, [authChecked]);

  return (
    <Router>
      {loading ? (
        // Display loading state while checking authentication
        <div className="flex space-x-2 justify-center items-center bg-white h-screen dark:invert">
          <span className="sr-only">Loading...</span>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-8 w-8 bg-black rounded-full animate-bounce"></div>
        </div>
      ) : (
        // Display the application once authentication check is complete
        <Routes>
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/" element={<FinalApp />} />
          <Route path="/en" element={<FinalAppEn />} />
          <Route path="/kz" element={<FinalAppKaz />} />
          <Route path="/auth" element={<AuthPage auth={authState} />} />
          <Route path="/register" element={<RegisterForm auth={authState} />} />
          <Route path="/karaganda" element={<Karaganda />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
