import React from "react";
import card1 from "../images/Rectangle 869 (1).png";
import card2 from "../images/Rectangle 869 (2).png";
import card3 from "../images/Rectangle 869 (3).png";
import card4 from "../images/Rectangle 869 (4).png";
import card5 from "../images/Rectangle 869 (5).png";
import card6 from "../images/Rectangle 869 (6).png";
import card7 from "../images/Rectangle 869 (7).png";
import card8 from "../images/Rectangle 869 (8).png";
import card9 from "../images/Rectangle 869 (9).png";
import card10 from "../images/Rectangle 869 (10).png";
import card11 from "../images/Rectangle 869 (11).png";
import card12 from "../images/Rectangle 869 (12).png";
import card13 from "../images/Rectangle 869 (13).png";
import card14 from "../images/Rectangle 869 (14).png";
import card15 from "../images/Rectangle 890.png";
import card16 from "../images/Rectangle 891.png";
import card17 from "../images/Rectangle 892.png";
function Whowithus() {
  const schoolData = [
    {
      image: card1,
      region: "ҚАРАГАНДЫ ОБЛЫСЫ",
      schools: "6 мектеп",
    },
    {
      image: card2,
      region: "ПАВЛОДАР ОБЛЫСЫ",
      schools: "8 мектеп",
    },
    // {
    //   image: card3,
    //   region: `ШЫҒЫСТАР ҚАЗАҚСТАН ОБЛЫСЫ`,
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card4,
    //   region: "ҰЛЫТАУ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card5,
    //   region: "АҚМОЛА ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card6,
    //   region: "ҚОСТАНАЙ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card7,
    //   region: "ЖЕТІСУ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card8,
    //   region: `СЕВЕР ҚАЗАҚСТАН ОБЛЫСЫ`,
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card9,
    //   region: "МАНҒИСТАУ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card10,
    //   region: `БАТЫС ҚАЗАҚСТАН ОБЛЫСЫ`,
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card11,
    //   region: "ҚЫЗЫЛОРДА ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card12,
    //   region: "ТҰРКЕСТАН ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card13,
    //   region: "АЛМАТЫ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    {
      image: card14,
      region: "АТЫРАУ ОБЛЫСЫ",
      schools: "12 мектеп",
    },
    // {
    //   image: card15,
    //   region: "АБАЙ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card16,
    //   region: "ЖАМБЫЛ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // {
    //   image: card17,
    //   region: "АҚТӨБЕ ОБЛЫСЫ",
    //   schools: "0 мектеп",
    // },
    // Добавьте данные для других мектептер по аналогии
  ];

  return (
    <div id="Map" className="mt-16 mb-48 px-4 mx-auto max-w-screen-2xl">
      <div className="flex flex-col">
        <div className="lg:flex lg:flex-row items-center lg:justify-between flex-col">
          <h1 className="text-neutral-400 text-lg font-normal font-['Inter'] whitespace-nowrap mt-4">
            — Бізбен бірге
          </h1>
          <div className="flex items-center mt-3 gap-2">
            <div className="w-4 h-4 bg-[#2C529A] rounded-full" />
            <span className="text-white text-base font-normal font-inter">
              26 мектеп бізбен бірге
            </span>
          </div>
        </div>
        <div className="   h-[0px] border border-neutral-400 mb-4 mt-4"></div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {schoolData.map((school, index) => (
            <SchoolCard key={index} {...school} />
          ))}
        </div>
      </div>
    </div>
  );
}

function SchoolCard({ image, region, schools }) {
  return (
    <div className="max-w-sm rounded-lg shadow-lg transform transition-transform hover:scale-105 cursor-pointer">
      <img className="rounded-t-lg" src={image} alt="" />
      <div className="flex justify-between py-2">
        <p className="text-white text-[14px] font-normal">{region}</p>
        <p className="text-white text-[14px] font-normal">{schools}</p>
      </div>
    </div>
  );
}

export default Whowithus;
