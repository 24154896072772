import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, push, serverTimestamp } from 'firebase/database';
import moment from 'moment';
export const firebaseConfig = {
  apiKey: "AIzaSyA_Nqgd08lAzEfAWbWuld-dOd50Gf5qiso",
  authDomain: "avrgroup-2b696.firebaseapp.com",
  databaseURL: "https://avrgroup-2b696-default-rtdb.firebaseio.com",
  projectId: "avrgroup-2b696",
  storageBucket: "avrgroup-2b696.appspot.com",
  messagingSenderId: "101371305775",
  appId: "1:101371305775:web:b65b73a3fd1278e80a0bda",
  measurementId: "G-JG3N128DR5"
};

const app = initializeApp(firebaseConfig, 'avr');
const auth = getAuth(app);
const db = getDatabase(app);

export const logVisit = async () => {
  const user = auth.currentUser;
  const userVisitsRef = ref(db, `visits/${user.uid}`);

  try {
    const timestamp = serverTimestamp();
    const date = moment(timestamp).format('DD.MM.YYYY');

    await push(userVisitsRef, {
      timestamp: timestamp,
      date: date
    });
  } catch (error) {
    console.error("Error logging visit:", error);
  }
};

export { app, db, auth };