import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ chartData }) => {
  return (
    <div className="bg-gradient-to-tl from-neutral-800 via-black to-stone-900 p-4 rounded-lg shadow-md mt-4">
      <h2 className="text-xl font-bold mb-4 text-white">Статистика по месяцам</h2>
      <div className="w-full mx-auto">
        <Bar
          data={chartData}
          options={{
            scales: {
              x: {
                type: "category", // Используем категориальную шкалу для месяцев
                position: "bottom",
                title: {
                  display: true,
                  text: "Месяц",
                  color:"#ffffff"
                },
              },
              y: {
                type: "linear",
                position: "left",
                title: {
                  display: true,
                  text: "Количество посещений",
                  color:"#ffffff"
                },
              },
            },
            plugins: {
              tooltip: {
                mode: "index", 
                callbacks: {
                  label: function (context) {
                    var label = context.dataset.label || "";
                    if (label) {
                      label += ": ";
                    }
                    if (context.parsed.y !== null) {
                      label += context.parsed.y + " посещений";
                    }
                    return label;
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default BarChart;
