import { useEffect, useRef, useState } from "react";
import VRHeadSet from "../images/Headset.png";
import JoyStickL from "../images/Joystick L.png";
import JoyStickR from "../images/Joystick R.png";
import plus from "../svg/plus.svg";
import vrmodal from "../images/vrmodal.png";
import vrmodal2 from "../images/vrmodal2.png";
import { useSpring, animated } from "react-spring";
import "../CSS/adaptive.css";
import PdfFile from "../../../pdf/Usermanualkk.pdf"
function Equipment() {
  // Состояния для отображения модальных окон и определения текущей модальной информации
  const [showJoysticks, setShowJoysticks] = useState(false);
  const [showHeadSet, setHeadSets] = useState(false);
  const equipmentRef = useRef();
  const [isDivVisible, setDivVisibility] = useState(false);
  const [isDivVisible2, setDivVisibility2] = useState(false);
  const [currentDiv, setCurrentDiv] = useState(1);
  const handleDownloadClick = () => {
    // Create a virtual link element
    const link = document.createElement("a");
    link.href = PdfFile; // Use the imported PDF file
    link.download = "Пайдаланушы нұсқаулығы"; // Specify the desired file name
    document.body.appendChild(link);

    // Trigger the click event on the link
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);
  };
  // Функции для открытия/закрытия модальных окон и установки текущей модальной информации
  /**
   * Функция toggleDivVisibility отвечает за изменение видимости модального окна.
   * Она также устанавливает значение currentDiv в 1 при каждом вызове.
   */
  const toggleDivVisibility = () => {
    setDivVisibility(!isDivVisible);
    setCurrentDiv(1);
  };

  const toggleDivVisibility2 = () => {
    setDivVisibility2(!isDivVisible2);
    setCurrentDiv(2);
  };

  // Обработчик скролла для отображения/скрытия элементов
  useEffect(() => {
    const handleScroll = () => {
      const element = equipmentRef.current;

      if (element) {
        const elementPosition = element.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (elementPosition.top < windowHeight) {
          // Элемент виден, показываем картинку
          setShowJoysticks(true);
          setHeadSets(true);
        } else {
          // Элемент не виден, скрываем картинку
          setShowJoysticks(false);
          setHeadSets(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Стили для элементов
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "hidden",
  };

  const vrHeadSetStyle = {
    position: "relative",
    opacity: showHeadSet ? 1 : 0,
    transition: "opacity 10s",

    zIndex: 2,
  };

  // const joysticksStyle = {
  //   opacity: showJoysticks ? 1 : 0,
  //   transition: "opacity 7.5s",
  //   position: "absolute",
  //   top: "50%",
  //   transform: "translateY(-50%)",
  // };

  const plusStyle = {
    opacity: showJoysticks ? 1 : 0,
    transition: "opacity 7s",
    position: "absolute",
  };
  const defaultLeftSpring = {
    from: { x: "50%", opacity: 0 },
    to: { x: "-100%", opacity: 1 },
    config: { duration: 2500 },
  };

  const defaultRightSpring = {
    from: { x: "-50%", opacity: 0 },
    to: { x: "50%", opacity: 1 },
    config: { duration: 2500 },
  };

  // Override spring configurations for smaller screens
  if (window.innerWidth <= 640) {
    defaultLeftSpring.to = { x: "-95%", opacity: 1 };
    defaultRightSpring.to = { x: "225%", opacity: 1 };
  } else if (window.innerWidth <= 768) {
    defaultLeftSpring.to = { x: "-95%", opacity: 1 };
    defaultRightSpring.to = { x: "185%", opacity: 1 };
  } else if (window.innerWidth <= 1024) {
    defaultLeftSpring.to = { x: "-95%", opacity: 1 };
    defaultRightSpring.to = { x: "185%", opacity: 1 };
  } else if (window.innerWidth >= 1240) {
    defaultLeftSpring.to = { x: "-105%", opacity: 1 };
    defaultRightSpring.to = { x: "165%", opacity: 1 };
  }

  // Create springs based on the configurations
  const leftSpring = useSpring(defaultLeftSpring);
  const rightSpring = useSpring(defaultRightSpring);
  return (
    <>
      <div
        ref={equipmentRef}
        id="presentation"
        className="mb-[100px] px-4 mx-auto max-w-screen-2xl"
        style={containerStyle}
      >
        <div className="flex" style={{ position: "relative" }}>
          <div style={vrHeadSetStyle}>
            <img
              loading="lazy"
              src={VRHeadSet}
              alt="VRHeadSet"
              className="lg:w-[400px] md:w-[400px] VrHead w-[220px]  xl:w-full  "
            />
            <animated.div
              // style={{ ...joysticksStyle }}
              style={leftSpring}
              className="absolute xl:top-[-150px] md:top-[-20px] top-[60px]  "
            >
              <img
                src={JoyStickL}
                alt="JoyStickL"
                className="lg:w-[200px] xl:w-full md:w-[205px] w-[90px]  VrSpring "
                loading="lazy"
              />
            </animated.div>

            <animated.div
              style={rightSpring}
              className=" absolute xl:top-[-150px] md:top-[-20px] top-[60px] "
            >
              <img
                src={JoyStickR}
                alt="JoyStickR"
                className="xl:w-[400px] md:w-[205px] VrSpring w-[90px]  "
                loading="lazy"
              />
            </animated.div>
            {/* Характеристики */}
            <div
              style={{ ...plusStyle }}
              className="xl:right-[-170px] xl:top-[345px] lg:right-[-60px] md:top-[200px] md:right-[-90px] top-[30px] right-[95px] plus1  image-with-hover-glow "
            >
              <img
                loading="lazy"
                src={plus}
                alt="plusStyle"
                className="xl:w-full xl:h-full   md:w-12 md:h-12 w-[35px] cursor-pointer"
                onClick={toggleDivVisibility}
              ></img>
              {isDivVisible && (
                <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50 backdrop-blur-lg overflow-auto">
                  <div className="bg-white  rounded-lg w-full max-w-lg md:max-w-xl lg:max-w-2xl">
                    <div className="flex justify-between mb-4 items-center">
                      <h1 className="text-black text-2xl font-bold font-inter leading-10 px-[25px] py-[15px]">
                        Сипаттамалары
                      </h1>
                      <div className="flex items-center gap-4">
                        <button
                          className=" -mt-2 mr-5 text-4xl text-blue-500 hover:text-gray-900 cursor-pointer"
                          onClick={toggleDivVisibility}
                        >
                          &times;
                        </button>
                      </div>
                    </div>

                    {currentDiv === 1 && (
                      <>
                        <div className="w-full mb-4  ">
                          <img
                            src={vrmodal2}
                            alt="VR Modal Image"
                            className="lg:w-full lg:h-full lg:object-cover"
                          />
                        </div>

                        <div className="text-lg  p-[30px] font-medium ">
                          <div className="flex justify-between">
                            <p>Өлшемі:</p>
                            <p className="text-[#565656]">2160 х 1200</p>
                          </div>
                          <hr className="w-full border-t border-dashed border-gray-300 my-2" />
                          <div className="flex justify-between">
                            <p>Кабель ұзындығы:</p>
                            <p className="text-[#565656]">10 метров</p>
                          </div>
                          <hr className="w-full border-t border-dashed border-gray-300 my-2" />
                          <div className="flex justify-between">
                            <p>Гарнитура салмағы:</p>
                            <p className="text-[#565656]">10 метров</p>
                          </div>
                          <hr className="w-full border-t border-dashed border-gray-300 my-2" />
                          <div className="flex justify-between">
                            <p>Көрінісі:</p>
                            <p className="text-[#565656]">110 градусов</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* Коротко об гарнитуре */}
            <div
              style={{ ...plusStyle }}
              className="xl:right-[313px] xl:top-[10px] lg:top-[40px]  md:right-[180px] md:top-[40px] top-[160px] right-[-40px] plus2  image-with-hover-glow "
              onClick={toggleDivVisibility2}
            >
              <img
                src={plus}
                alt="plusStyle"
                className="xl:w-full xl:h-full md:w-12 md:h-12 w-[35px]  cursor-pointer"
              ></img>
              {isDivVisible2 && (
                <div className="fixed inset-0 flex items-center justify-center z-10 bg-black bg-opacity-50 backdrop-blur-lg overflow-auto">
                  <div className="bg-white  rounded-lg w-full max-w-lg md:max-w-xl lg:max-w-2xl">
                    <div className="flex justify-between mb-4 items-center">
                      <h1 className="text-black text-2xl font-bold  leading-10 px-[25px] py-[15px]">
                        Oculus Quest 2
                      </h1>
                      <div className="flex items-center gap-4">
                        <button
                          className=" -mt-2 mr-5 text-4xl text-blue-500 hover:text-gray-900 cursor-pointer"
                          onClick={toggleDivVisibility2}
                        >
                          &times;
                        </button>
                      </div>
                    </div>

                    {currentDiv === 2 && (
                      <>
                        <div className="w-full mb-4">
                          <img
                            src={vrmodal}
                            alt="VR Modal Image"
                            className="lg:w-full lg:h-full lg:object-cover"
                          />
                        </div>
                        <div className="p-[30px] -mt-4 ">
                          <h2 className="font-bold text-xl w-full font-jost text-[#000]">
                            Гарнитура жайлы қысқаша
                          </h2>
                          <p className="text-[#000000] text-lg mb-[15px]  font-medium">
                            Oculus Quest 2 - Виртуалды әлемге компьютер мен
                            сымсыз енуге және ойнауға <br />
                            мүмкіндік беретін, жоғары сападағы дербес
                            VR-көзілдірік. Ол <br />
                            көптеген қосымшаларды көтере алып, виртуал әлеммен
                            қоғамдық байланысты қамтамасыз етеді.
                          </p>
                          <h2 className="font-bold  text-xl w-full font-jost text-[#000]">
                            Мүмкіндіктер:
                          </h2>
                          <p className="text-[#000000] text-lg font-medium">
                            Дербес құрылғы Qualcomm xr2 процессорымен
                            жабдықталған және 6 ГБ жедел жады бар. Сондай-ақ
                            оның 58 мм, 63 мм және 68 мм аралық қашықтықты
                            реттеуге арналған үш позиция арасында жылдам ауысу
                            механизмі қосылған экраны бар.
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <center>
        <button
          className="text-white font-semibold bg-gradient-to-bl from-indigo-500 via-blue-300 to-blue-500 hover:bg-gradient-to-bl hover:from-indigo-700 hover:via-blue-400 hover:to-blue-700 py-2.5 px-4 rounded-full hover:duration-300"
          onClick={handleDownloadClick}
        >
         Нұсқаулықты жүктеу алыңыз
        </button>
      </center>
    </>
  );
}

export default Equipment;
