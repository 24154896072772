import React, { useState } from 'react';
import { Link as LinkRouter, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';

function RegisterForm({ auth }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Валидация электронной почты с использованием простого регулярного выражения
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(emailRegex.test(newEmail) ? '' : 'Invalid email address');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Регистрация пользователя в Firebase
      await createUserWithEmailAndPassword(auth, email, password);

      console.log('Registration successful');
      navigate('/dashboard');
    } catch (error) {
      console.error('Registration failed:', error);
      const errorMessage = error.message;
      console.error(errorMessage || 'Unknown error');
    }
  };
  return (
    <div className="bg-gradient-to-tl from-neutral-800 via-black to-stone-900 h-screen w-screen">
      <div className="flex flex-col items-center flex-1 h-full justify-center px-4 sm:px-0">
        <div
          className="flex rounded-lg shadow-lg w-full sm:w-3/4 lg:w-2/3 bg-white sm:mx-0"
          style={{ height: "550px" }}
        >
          <div
            className="hidden md:block md:w-1/2 rounded-l-lg"
            style={{
              background:
                "url('https://sun9-49.userapi.com/impg/xABKDTP56GI2aE85b4pe6_4vU9Dl7UEOiH3grA/g3BEJjsgqtY.jpg?size=580x580&quality=96&sign=2a9ed4a4ef4f797942bbc391e85bdf03&type=album')",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="flex flex-col w-full md:w-1/2 p-4">
            <div className="flex flex-col flex-1 justify-center mb-8">
              <h1 className="text-2xl text-center font-semibold text-black">
                Welcome Back
              </h1>
              <div className="w-full mt-4">
                <form
                  className="form-horizontal w-3/4 mx-auto"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col mt-4">
                    <input
                      id="email"
                      type="text"
                      className={`flex-grow h-8 py-6 px-2 border rounded bg-transparent ${
                        emailError ? "border-red-500" : ""
                      }`}
                      name="email"
                      value={email}
                      required
                      onChange={handleEmailChange}
                      placeholder="Email"
                    />
                    {emailError && (
                      <p className="text-red-500 text-xs mt-1">{emailError}</p>
                    )}
                  </div>
                  <div className="flex flex-col mt-4 relative">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      className="flex-grow h-8 py-6 px-2 border rounded bg-transparent pr-10"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={handleTogglePasswordVisibility}
                      className="absolute top-1/2 right-2 transform -translate-y-1/2"
                    >
                      {showPassword ? "👁️" : "👁️‍🗨️"}
                    </button>
                  </div>

                  <div className="flex flex-col mt-4">
                    <input
                      id="confirmPassword"
                      type="password"
                      className="flex-grow h-8 py-6 px-2 border rounded bg-transparent "
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                      placeholder="Confirm Password"
                    />
                  </div>

                  <div className="flex flex-col mt-8">
                    <button
                      type="submit"
                      className="bg-[black] hover:bg-blue-700 text-white text-sm font-semibold py-4 px-6 rounded transition-transform duration-300 ease-in-out"
                    >
                      Register
                    </button>
                  </div>
                </form>
                <div className="text-center mt-4">
                  <p className="text-black">
                    Already have an account?{" "}
                    <LinkRouter
                      to="/auth"
                      className="text-blue-500 hover:underline"
                    >
                      Login here
                    </LinkRouter>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
