import React, { useState } from "react";
import arrow from "../svg/droparrow.svg";

function Questions() {
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleAccordion = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const questionsData = [
    {
      question: "Что такое VR-продукт для образования?",
      content: `
      MetaStudy - это инновационная технология, которая погружает учащихся в виртуальное пространство, где они могут изучать различные предметы, включая физику и историю Казахстана, в интерактивном формате.`,
    },
    {
      question:
        "Какие преимущества VR образования в сравнении с традиционными методами?",
      content: `VR-образование обогащает обучающий опыт, позволяя учащимся взаимодействовать с учебным материалом, исследовать и погружаться в исторические события или физические явления. Оно способствует лучшему пониманию и запоминанию информации.`,
    },
    {
      question:
        "Какие конкретные учебные программы и предметы покрывает ваш VR-продукт?",
      content: `
      Мы предлагаем разнообразные учебные программы, включая историю Казахстана и физику. Наш каталог регулярно обновляется, чтобы удовлетворить потребности образовательных учреждений.`,
    },
    {
      question: "Каковы технические требования для использования VR-продукта?",
      content: `Для использования нашего VR-продукта вам потребуется совместимый VR-гарнитур и доступ к устройству с подходящими характеристиками. Мы предоставляем VR-очки и подробные технические рекомендации.`,
    },
    {
      question:
        "Могу ли я рассчитывать на помощь при установке и настройке VR-продукта для образования?",
      content: `Да, конечно! Мы предоставляем полное сопровождение при установке и настройке. Наши специалисты приедут к вам, установят всё необходимое оборудование и настроят программное обеспечение, гарантируя готовность к использованию.`,
    },
    {
      question: "Как происходит доставка и установка оборудования?",
      content: `Мы предоставляем доставку оборудования непосредственно в ваше учебное заведение. Наши специалисты профессионально устанавливают и настраивают гарнитуры, обеспечивая правильное функционирование продукта.`,
    },
    {
      question: "Предоставляется ли обучение по использованию VR-продукта?",
      content: `Да, мы предоставляем обучение. Наши эксперты проведут обучение учителей по использованию гарнитур и программного обеспечения, чтобы вы могли наилучшим образом интегрировать его в учебный процесс.`,
    },
  ];

  return (
    <div className="max-w-screen-2xl mx-auto  p-2 px-4 items-center  mt-[170px]">
      <h1
        id="FAQ"
        className="text-base font-semibold text-left text-[#8E8E8E] mb-6 font-inter  "
      >
        — Часто задаваемые вопросы
      </h1>
      {questionsData.map((item, index) => (
        <div
          className={`border-y ${
            openIndexes.includes(index) ? "border-[#4F9EFF]" : "border-[#4F9EFF] p-2 "
          } hover:border-[#4F9EFF]`}
        >
          <h2>
            <button
              type="button"
              className={`flex items-center justify-between w-full py-5 font-medium text-left text-black-500 ${
                openIndexes.includes(index) ? "bg-transparent text-white" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <div className="flex flex-col">
                <span className="font-normal text-[#8E8E8E] text-lg font-inter">
                  {item.question}
                </span>
              </div>
              <img
                src={arrow}
                className={`w-3 h-3 shrink-0 transform ${
                  openIndexes.includes(index) ? "rotate-180" : "rotate-0"
                }`}
                alt="Arrow"
              />
            </button>
          </h2>
          {openIndexes.includes(index) && (
            <div className="text-white text-lg font-semibold font-inter">
              {item.content}
            </div>
          )}
          <hr
            className={`mt-2 h-[1px] border-0 ${
              openIndexes.includes(index)
                ? "border-red-700"
                : "border-transparent"
            }`}
          />
        </div>
      ))}
    </div>
  );
}

export default Questions;
