import React from "react";
import Header from "../Layouts/Header";
import background from "../images/background.png";
import background2 from "../images/background2.png";
import background3 from "../images/background3.png";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
function Main() {
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [currentImage, setCurrentImage] = useState(0);
  const imageUrls = [background, background2, background3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % imageUrls.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [imageUrls.length]);
  return (
    <div>
      <div
        className="bg-transparent transition-all duration-1000 ease-in-out"
        style={{
          backgroundImage: `url(${imageUrls[currentImage]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Header />
        <div
          className="mt-[560px] flex-col w-full border-t backdrop-blur-md"
          style={{
            background:
              "linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.25) 70%)",
            backdropFilter: "blur(10px)",
          }}
        >
          <div className=" mx-auto max-w-screen-2xl p-6   ">
            <motion.div
              className="flex items-center mt-2 "
              ref={ref1}
              initial={{ opacity: 0, x: -100 }}
              animate={inView1 ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 1 }}
            >
              <img src="./img/MainLogo.png" className="mb-4"></img>
            </motion.div>

            <motion.p
              className="lg:text-2xl md:text-xl text-md text-white font-normal mb-2 font-inter"
              ref={ref2}
              initial={{ opacity: 0, x: -100 }}
              animate={inView2 ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 1 }}
            >
              Біздің арқылы жаңа дағдыларды <br /> үйреніңіз Білімге арналған VR
              өнімі.
            </motion.p>
            <motion.button
              ref={ref3}
              initial={{ opacity: 0, x: -100 }}
              animate={inView3 ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 1 }}
              className="text-[#028EC3] text-xl font-semibold underline mb-2 hover:scale-105 hover:transition-transform hover:ease-in-out font-inter"
            >
              <Link
                to="contactwithus" // Указываем имя секции, к которой нужно прокрутиться
                spy={true}
                smooth={true}
                offset={-70} // Отступ, если у вас есть фиксированное меню
                duration={500} // Продолжительность анимации
              >
                Бізбен байланысыңыз
              </Link>
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
