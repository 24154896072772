import React, { useState } from "react";
import droparrow from "../svg/droparrow.svg";
import worldlang from "../svg/worldlang.svg";
import { Link } from "react-scroll";
import background from "../images/background.png";
import { Link as LinkRouter } from "react-router-dom";

function Header() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleRotation = () => {
    setIsRotated(!isRotated);
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="flex justify-between items-center max-w-screen-2xl mx-auto relative p-6 ">
      <div className="flex items-center ">
        {" "}
        <img src="./img/Group 13 (4).png"></img>
      </div>

      <div className="hidden lg:block">
        <ul className="flex items-center text-white xl:gap-[20px]  border rounded-full xl:px-4 lg:px-1 cursor-pointer   font-normal  font-inter">
          <Link
            to="presentation"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {" "}
            <li className="p-2 hover:text-[#005AC5]">Что мы представляем</li>
          </Link>

          <Link to="Map" spy={true} smooth={true} offset={-70} duration={500}>
            {" "}
            <li className="p-2 hover:text-[#005AC5]">Кто уже с нами</li>
          </Link>

          <Link
            to="physics"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {" "}
            <li className="p-2 hover:text-[#005AC5]">Физика</li>
          </Link>

          {/* <Link
            to="history"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {" "}
            <li className="p-2 hover:text-[#005AC5]">История</li>
          </Link> */}

          <Link
            to="tariff"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {" "}
            <li className="p-2 hover:text-[#005AC5]">Тарифы</li>
          </Link>

          <Link to="FAQ" spy={true} smooth={true} offset={-70} duration={500}>
            {" "}
            <li className="p-2 hover:text-[#005AC5]">FAQ</li>
          </Link>

          <Link
            to="contactwithus"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            {" "}
            <li className="p-2 hover:text-[#005AC5]">Контакты</li>
          </Link>

          <div>
            <LinkRouter
              to="/auth"
              onClick={closeMenu}
              className="p-2 hover:text-[#005AC5] transition duration-300"
            >
              Авторизация
            </LinkRouter>
            /
            <LinkRouter
              to="/register"
              onClick={closeMenu}
              className="p-2 hover:text-[#005AC5] transition duration-300"
            >
              Регистрация
            </LinkRouter>
          </div>
        </ul>
      </div>
      <div className="text-white p-2 flex">
        <div className="relative inline-block">
          <div className="flex" onClick={toggleRotation}>
            <img src={worldlang} alt="World Language" />
            <button
              onClick={toggleDropdown}
              className=" inline-flex items-center font-medium justify-center px-2 py-2 text-sm text-white rounded-lg cursor-pointer"
            >
              Русский
            </button>
            <img
              onClick={toggleDropdown}
              src={droparrow}
              alt="Drop Arrow"
              className={`transition-transform transform md:w-4 w-3   ${
                isRotated ? "rotate-180" : ""
              }`}
            />
          </div>

          {isDropdownOpen && (
            <div className="z-50 absolute my-4 px-6 text-base list-none border border-black bg-white rounded-lg shadow-lg">
              <ul className="py-2 font-medium" role="none">
                <LinkRouter to="/en">
                  {" "}
                  <li className="block px-4 py-2 text-sm text-black hover:scale-110">
                    English
                  </li>
                </LinkRouter>

                <LinkRouter to="/">
                  <li className="block px-4 py-2 text-sm text-black hover:scale-110">
                    Русский
                  </li>
                </LinkRouter>

                <LinkRouter to="/kz">
                  {" "}
                  <li className="block px-4 py-2 text-sm text-black hover:scale-110">
                    Қазақша
                  </li>
                </LinkRouter>
              </ul>
            </div>
          )}
        </div>
        {/* MOBILE MENU */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="block text-white p-2 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <div
            className={`fixed top-0 left-0 w-1/2 h-screen bg-black text-white z-50 transition-transform transform  rounded ${
              isMenuOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <div className="flex items-center p-4">
              <button
                className="absolute top-4 right-4 text-red-800 font-bold text-4xl"
                onClick={closeMenu}
              >
                &times;
              </button>
              <div className="">
                <img src="./img/Group 13 (4).png" alt="Logo" />
              </div>
            </div>

            <ul className=" px-4 h-full flex flex-col  text-left  space-y-4 text-lg font-semibold text-[#028EC3]">
              <Link
                to="presentation"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                {" "}
                <li className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white border-b border-white ">
                  Что мы представляем
                </li>
              </Link>
              <Link
                to="Map"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                {" "}
                <li className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white">
                  Кто уже с нами
                </li>
              </Link>
              <Link
                to="physics"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                {" "}
                <li className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white">
                  Физика
                </li>
              </Link>
              {/* <Link
                  to="history"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={closeMenu} 
                >
                  {" "}
                  <li className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white">История</li>
                </Link> */}
              <Link
                to="tariff"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                {" "}
                <li className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white">
                  Тарифы
                </li>
              </Link>
              <Link
                to="FAQ"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                {" "}
                <li className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white">
                  FAQ
                </li>
              </Link>
              <Link
                to="contactwithus"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={closeMenu}
              >
                {" "}
                <li className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white">
                  Контакты
                </li>
              </Link>
              <LinkRouter
                to="/auth"
                onClick={closeMenu}
                className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white"
              >
                Авторизация
              </LinkRouter>

              <LinkRouter
                to="/register"
                onClick={closeMenu}
                className="p-4 hover:text-[#005AC5] transition duration-300 border-b border-white"
              >
                Регистрация
              </LinkRouter>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
