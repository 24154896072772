import { React, useState } from "react";
import contactimg from "../images/contact.png";
// Import the functions you need from the SDKs you need
import { getDatabase, ref, push, set } from "firebase/database";
import { initializeApp } from "firebase/app";

function Contact() {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyD_aSnqiw1FowDNjbVCgkIZr_BNYOyqMW4",
    authDomain: "avr-form-data.firebaseapp.com",
    projectId: "avr-form-data",
    storageBucket: "avr-form-data.appspot.com",
    messagingSenderId: "1090192526374",
    appId: "1:1090192526374:web:d9ff2049184978ba80aae7",
    measurementId: "G-82XKE7ZEK1",
  };
  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);

  // Initialize Firebase

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    organization: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || !formData.phone || !formData.organization) {
      // Handle form validation error, e.g., display an error message
      console.error("Please fill out all required fields.");
      return;
    }
    setIsFormSubmitted(true);
    // Get a reference to the Firebase Realtime Database
    const db = getDatabase();

    // Set the data in a "contacts" node (you can change the node name)
    const contactRef = push(ref(db, "contacts"));

    // Push the form data to the database
    set(contactRef, formData);

    // Clear the form inputs after submission
    setFormData({
      name: "",
      phone: "",
      organization: "",
    });
  };
  return (
    <div
      id="contactwithus"
      className="px-4 mt- sm:mt-20 lg:mt-40 p-2 mx-auto items-center max-w-screen-2xl"
    >
      <div className="">
        <h1 className="text-neutral-400 text-lg font-normal font-['Inter'] whitespace-nowrap mt-[72px]">
          — Свяжитесь с нами
        </h1>
      </div>
      <div className="h-0 border border-neutral-400 mb-4"></div>
      <div className="flex flex-col sm:flex-col lg:flex-row gap-8 mt-8">
        <form className="xl:w-1/2 w-full" onSubmit={handleSubmit}>
          <h1 className="text-white text-2xl mb-4 font-normal">
            Если вас заинтересовало наше <br /> предложение, то свяжитесь с нами
          </h1>
          <div className="mb-6">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="bg-transparent border border-gray-600 text-lg rounded-lg block w-full p-2.5 font-inter text-white"
              placeholder="Ваше имя*"
              required
            />
          </div>
          <div className="mb-6">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={(e) => {
                const onlyNumbers = e.target.value.replace(/[^0-9+]/g, ""); // Оставить только цифры
                setFormData({
                  ...formData,
                  phone: onlyNumbers,
                });
              }}
              className="bg-transparent border border-gray-600 text-lg rounded-lg w-full p-2.5 font-inter text-white"
              required
              placeholder="Номер телефона*"
            />
          </div>

          <div className="mb-6">
            <input
              type="text"
              name="organization"
              value={formData.organization}
              onChange={handleInputChange}
              className="bg-transparent border border-gray-600 text-lg rounded-lg w-full p-2.5 font-inter text-white"
              required
              placeholder="Организация*"
            />
          </div>
          <button
            type="submit"
            className="border border-gray-800 font-semibold text-lg rounded-md w-full p-2.5 bg-[white] hover:bg-gray-200 text-black font-inter"
          >
            {isFormSubmitted ? "Форма отправлена!" : "Связаться с нами"}
          </button>
        </form>
        <img src={contactimg} alt="Contact" className="  w-full" />
      </div>
    </div>
  );
}

export default Contact;
