import React from "react";

function Footer() {
  return (
    <footer className="" id="contacts">
      <div className="mt-[200px] mx-auto w-full max-w-screen-2xl p-4 py-6 lg:py-8">
        <div className="h-[0px] border border-neutral-400 mb-4"></div>
        <div className="lg:flex lg:justify-between flex-col lg:flex-row">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <a href="" className="flex items-center">
              <div>
                <div>
                  <div className="flex">
                    <img src="./img/Group 13 (4).png"></img>
                  </div>
                </div>

                <p className="mt-3 text-left font-normal text-lg mb-2  text-white">
                  Educational platform with <br /> training courses for <br />
                  schoolchildren and students
                </p>
              </div>
            </a>
          </div>
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between gap-4 md:gap-8 lg:gap-48">
            <div className="mb-2 md:mb-0 text-white">
              <h2 className="mb-3 text-lg font-bold ">Our SNS</h2>
              <ul className="space-y-2 font-medium">
                <li className="">
                  <a
                    href=""
                    className="hover:underline hover:text-[#5748FF] font-inter text-lg font-normal"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    className="hover:underline  hover:text-[#5748FF] font-inter text-lg font-normal"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    className="hover:underline  hover:text-[#5748FF]font-inter text-lg font-normal"
                  >
                    Telegram
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-3  text-lg font-bold text-white ">
                Contact details
              </h2>
              <ul className=" font-medium">
                <li className="mb-2">
                  <a
                    href="https://wa.me/+77073845529"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-500 text-lg foint-inter font-normal text-white"
                  >
                    +7 707 384 5529
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 mx-auto w-full max-w-screen-lg text-sm text-center  font-normal">
        <a
          href=""
          className="hover:underline text-lg font-medium font-inter text-white "
        >
          ©MetaStudy CopyRight
        </a>
      </div>
    </footer>
  );
}

export default Footer;
