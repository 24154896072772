import React, { useState } from "react";
import arrow from "../svg/droparrow.svg";

function Questions() {
  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleAccordion = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const questionsData = [
    {
      question: `Білімге арналған VR өнімі дегеніміз не?
      `,
      content: `
      MetaStudy – студенттерге интерактивті форматта әртүрлі пәндерді, соның ішінде физика мен Қазақстан тарихын оқи алатын, виртуалды кеңістікке батыратын инновациялық технология.
      `,
    },
    {
      question: `VR білім берудің дәстүрлі әдістермен салыстырғанда қандай артықшылықтары бар?
      `,
      content: `VR білім беру студенттерге оқу материалымен әрекеттесуге, тарихи оқиғаларды немесе физикалық құбылыстарды зерттеуге және сол әлемге енуге мүмкіндік беру арқылы оқу тәжірибесін байытады. Бұл ақпаратты жақсы түсінуге және есте сақтауға ықпал етеді.
      `,
    },
    {
      question: `VR өніміңіз қандай нақты оқу бағдарламалары мен пәндерді қамтиды?
      `,
      content: `
      
Біз әртүрлі оқу бағдарламаларын ұсынамыз, соның ішінде Қазақстан тарихы мен физикасы қамтылған. Біздің каталог оқу орындарының қажеттіліктерін қанағаттандыру үшін үнемі жаңартылып отырады.
`,
    },
    {
      question: `VR өнімін пайдаланудың техникалық талаптары қандай?
      `,
      content: `VR өнімін пайдалану үшін сізге үйлесімді VR гарнитурасы және сәйкес техникалық сипаттамалары бар құрылғыға рұқсат қажет. Біз VR көзілдіріктерін және толық түсіндірілген техникалық ұсыныстарды ұсынамыз.
      `,
    },
    {
      question: `Білімге арналған VR өнімін орнату және конфигурациялау кезінде көмекке сене аламын ба?
      `,
      content: `
      Иә, әрине! Біз орнату және конфигурациялау кезінде толық қолдау көрсетеміз. Біздің мамандар сізге келіп, барлық қажетті жабдықты орнатады және бағдарламалық жасақтаманы конфигурациялап, пайдалануға дайындығын қамтамасыз етеді.
      `,
    },
    {
      question: `Жабдық қалай жеткізіледі және орнатылады?
      `,
      content: `Біз жабдықты тікелей сіздің оқу орныңызға жеткізуді қамтамасыз етеміз. Біздің мамандар өнімнің дұрыс жұмыс істеуін қамтамасыз ете отырып, гарнитураларды кәсіби түрде орнатып, конфигурациялайды.
      `,
    },
    {
      question: `
      VR өнімін пайдалану бойынша оқыту қарастырылған ба?
      `,
      content: `Иә, біз оқытуды қамтамасыз етеміз. Біздің сарапшылар мұғалімдерге гарнитураны және бағдарламалық құралды пайдалану бойынша оқытуды ұсынады, осылайша сіз оны оқу үдерісіне жақсырақ біріктіре аласыз.`,
    },
  ];

  return (
    <div className="max-w-screen-2xl mx-auto  p-2 px-4 items-center font-['Inter'] mt-[150px]">
      <h1
        id="FAQ"
        className="text-xl font-semibold text-left text-[#8E8E8E] mb-6  "
      >
        — Жиі қойылатын сұрақтар
      </h1>
      {questionsData.map((item, index) => (
         <div
         className={`border-y ${
           openIndexes.includes(index) ? "border-[#4F9EFF]" : "border-[#4F9EFF] p-2 "
         } hover:border-[#4F9EFF]`}
       >
          <h2>
            <button
              type="button"
              className={`flex items-center justify-between w-full py-5 font-medium text-left text-black-500 ${
                openIndexes.includes(index) ? "bg-transparent text-white" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <div className="flex flex-col">
                <span className="font-bold  text-[#8E8E8E] text-xl font-['Inter']">
                  {item.question}
                </span>
              </div>
              <img
                src={arrow}
                className={`w-3 h-3 shrink-0 transform ${
                  openIndexes.includes(index) ? "rotate-180" : "rotate-0"
                }`}
                alt="Arrow"
              />
            </button>
          </h2>
          {openIndexes.includes(index) && (
            <div className="text-white text-xl   font-semibold font-['Inter']">
              {item.content}
            </div>
          )}
          <hr className="mt-2 h-[1px] bg-black border-0" />
        </div>
      ))}
    </div>
  );
}

export default Questions;
