import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const TimeChartKaraganda = ({ selectedMonth }) => {
  const [timeData, setTimeData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const generateRandomData = () => {
      const hourlyVisits = Array.from({ length: 24 }, () =>
        Math.floor(Math.random() * (10 - 1 + 1) + 1)
      );

      const chartData = {
        labels: Array.from({ length: 24 }, (_, i) => i),
        datasets: [
          {
            label: "Посещения",
            data: hourlyVisits,
            fill: false,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 2,
          },
        ],
      };

      setTimeData(chartData);
      setIsLoading(false);
    };

    generateRandomData();
  }, [selectedMonth]);

  return (
    <div className="bg-gradient-to-tl from-neutral-800 via-black to-stone-900 p-4 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4 text-white">
        Посещаемость по часам
      </h2>
      {isLoading ? (
        <p>Загрузка...</p>
      ) : (
        <Line
          data={timeData}
          options={{
            scales: {
              x: {
                type: "linear",
                position: "bottom",
                title: {
                  display: true,
                  text: "Час суток",
                  color: "#ffffff",
                },
              },
              y: {
                type: "linear",
                position: "left",
                title: {
                  display: true,
                  text: "Количество посещений",
                  color: "#ffffff",
                },
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const label = context.dataset.label || "";
                    const visitHour = context.parsed.x;
                    const visitCount = context.parsed.y;
                    const formattedHour =
                      visitHour < 10 ? `0${visitHour}` : visitHour;
                    const date = new Date(2024, selectedMonth - 1, 1, visitHour);
                    const formattedDate = date.toLocaleString("default", {
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    });

                    return `${label}: ${visitCount} посещений (${formattedDate})`;
                  },
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default TimeChartKaraganda;
